import { useEffect, useState } from 'react';
 
let myTimer;
 
export default function UseEffectSample(props) {
  const [timerIsActive, setTimerIsActive] = useState(false);
 
  const { timerDuration } = props; // using destructuring to pull out specific props values
 
  useEffect(() => {
    if (!timerIsActive) {
      setTimerIsActive(true);
      myTimer = setTimeout(() => {
        setTimerIsActive(false);
        console.log('timer');
      }, timerDuration);
    }
  }, []);
};