

export function authHeader(method, auth) {
    // return authorization header with basic auth credentials
    // let user = JSON.parse(localStorage.getItem('user'));
    let authEncodedBase64 = window.btoa(auth.username + ':' + auth.password);
    var authData = authEncodedBase64;

    const requestOptions = {
        method: method,
        mode: 'cors',
        headers: {
            'Authorization': 'Basic ' + authData
        },
    }

    if (authData) {
        return requestOptions;
    } else {
        return {};
    }
}