import React from "react";

const CartContext = React.createContext({
    items: [],
    totalAmount: 0,
    addItem: (item) => {},
    removeItem: (id) => {},
    updateItem: (item) => {},
    hiItem: () => {},
    addItemToList: (item) => {},
    CONST_PRICE_TIBETKA: 40,
    CONST_PRICE_KOMBUCHA: 40,
    totalPrice: 0,
    removeAll: _ => {},
    checkSpinnerVisibility: false,
    setSpinnerVisibility: (flag) => {},
    isLoggedIn: () => {},
    loginParams: {},
    changeLoginParam: () => {},
    getLoginUsername: () => {},
    logout: () => {},
    login: () => {},
    checkLogin: () => {},
    authData: {},
    setAuthData: () => {},

});

export default CartContext;