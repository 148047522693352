import React, { useContext, useEffect, useState } from "react";
// import classes from './ModalInfo.module.css';
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import CartContext from "../../store/cart-context";
import { authHeader } from "../FormCart/helpers/auth-header";
import GlobalVariables, { GlobalPropertiesFormControls } from "../Util/GlobalVariables";

ReactModal.setAppElement('#main');

const ModalLogin = (props) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const [isOpen, setIsOpen] = useState();

    const cartContext = useContext(CartContext);


    let subtitle;

    useEffect(_ => {
    }, []);


    function handleOnClickCloseModal() {
        props.handleCloseModal();
    }

    async function login() {
        const login = {
            username: username,
            password: password
        };

        try {
            const response = await fetch(GlobalVariables.URL_REST_API_BASE + "login", authHeader('GET', login));

            if (!response.ok) {
                throw new Error('something went wrong!');
            }
            const result = await response.json();
            cartContext.setAuthData(login);
            return result;
        } catch (error) {
            throw error;
        }
    }

    function handleOnClickSaveItem(event) {

        event.preventDefault();

        login().then(res => {
            console.log(res);
            toast('Uzivatel ' + username + ' je prihlasen');

            // cartContext.changeLoginParam({ login: true, username: username });
            // props.changeLoginParam();

            // let authEncodedBase64 = window.btoa(username + ':' + password);
            // localStorage.setItem('username_' + username, authEncodedBase64);
            // localStorage.setItem('username', username);
            // localStorage.setItem('login', true);

            cartContext.login(username, password);

        }).catch(err => {
            console.log(err);
            toast('Incorrect credentials for user ' + username);

            // cartContext.changeLoginParam({ login: false, username: 'none' });

            localStorage.removeItem('login');
            localStorage.removeItem('username_' + username);
            localStorage.removeItem('username');
        })

        // props.handleCloseModal();
    }

    function handleAfterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function setValue(e) {
        console.log(e.target.value);
        console.log(e.target.id);
        let value = e.target.value;
        let id = e.target.id;

        if (id === "username") {
            setUsername(value);
        }
        if (id === "password") {
            setPassword(value);
        }
    }

    function handleKeyDown(event) {

        if (event.key === 'Enter') {
            // TODO validation
            // handleOnClickSaveItem();
        }

    }

    return (
        <div>
            <ReactModal
                isOpen={props.display}
                onAfterOpen={handleAfterOpenModal}
                onRequestClose={handleOnClickCloseModal}
                // style={customStyles}
                className="modal-custom login-form"
                overlayClassName="overlay-custom"
                contentLabel="Example Modal"
            >

                {/* <div className={classes.backdrop}> */}
                {/* <div className={classes.modal}> */}

                <form onSubmit={handleOnClickSaveItem}>
                    <div class="modal-header">

                        <div class="iconBird"><i class="fa-solid fa-dove"></i></div>
                        <div>
                            Login
                        </div>
                        <div onClick={handleOnClickCloseModal} class="iconClose"><i class="fa-solid fa-xmark"></i></div>

                    </div>

                    <div class="modal-content">
                        <div class="d-flex flex-column gap-4 w-75 mx-auto">
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>{GlobalPropertiesFormControls.LABEL_EMAIL}</label>
                                </div>
                                <div class="col-7">
                                    <input required onKeyDown={handleKeyDown} id="username" type="text" onChange={(e) => setValue(e)} value={username} class="form-control rounded-left-none" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>{GlobalPropertiesFormControls.LABEL_PASSWORD}</label>
                                </div>
                                <div class="col-7">
                                    <input required onKeyDown={handleKeyDown} id="password" type="password" onChange={(e) => setValue(e)} value={password} class="form-control rounded-left-none" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button className="button" onKeyDown={(e) => handleKeyDown(e)}>{GlobalPropertiesFormControls.BUTTON_LOGIN}</button>
                    </div>
                </form>
            </ReactModal >
        </div>
    );
}

export default ModalLogin;
