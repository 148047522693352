import React, { useContext, useEffect, useState } from "react";
import CartContext from "../../store/cart-context";
import GlobalVariables, { GlobalPropertiesHeader, GlobalPropertiesKombucha, debugMessage } from "../Util/GlobalVariables";
import ModalInfo from "../Modal/ModalInfo";
import ModalLogin from "../Modal/ModalLogin";
import { toast } from "react-toastify";

export default function Header() {

    const [arrowVisible, setArrowVisible] = useState(0);
    const [cartBoxVisible, setCartBoxVisible] = useState(0);
    const headerWaveHeight = '800';
    const headerWaveBottomHeight = '25';
    const [displayModal, setDisplayModal] = useState(false);

    const cartCtx = useContext(CartContext);

    function handleCloseModal() {
        setDisplayModal(false);
    }

    function waveTop() {
        var res;
        var linearGradient = 'url(#grad3)';
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>;
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,224L48,224C96,224,192,224,288,192C384,160,480,96,576,96C672,96,768,160,864,202.7C960,245,1056,267,1152,240C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>;
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,266.7C560,277,640,267,720,234.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,165.3C1280,192,1360,224,1400,240L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>;
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,96L12.6,101.3C25.3,107,51,117,76,138.7C101.1,160,126,192,152,197.3C176.8,203,202,181,227,149.3C252.6,117,278,75,303,58.7C328.4,43,354,53,379,90.7C404.2,128,429,192,455,218.7C480,245,505,235,531,224C555.8,213,581,203,606,192C631.6,181,657,171,682,170.7C707.4,171,733,181,758,197.3C783.2,213,808,235,834,234.7C858.9,235,884,213,909,208C934.7,203,960,213,985,202.7C1010.5,192,1036,160,1061,128C1086.3,96,1112,64,1137,74.7C1162.1,85,1187,139,1213,138.7C1237.9,139,1263,85,1288,90.7C1313.7,96,1339,160,1364,202.7C1389.5,245,1415,267,1427,277.3L1440,288L1440,320L1427.4,320C1414.7,320,1389,320,1364,320C1338.9,320,1314,320,1288,320C1263.2,320,1238,320,1213,320C1187.4,320,1162,320,1137,320C1111.6,320,1086,320,1061,320C1035.8,320,1011,320,985,320C960,320,935,320,909,320C884.2,320,859,320,834,320C808.4,320,783,320,758,320C732.6,320,707,320,682,320C656.8,320,632,320,606,320C581.1,320,556,320,531,320C505.3,320,480,320,455,320C429.5,320,404,320,379,320C353.7,320,328,320,303,320C277.9,320,253,320,227,320C202.1,320,177,320,152,320C126.3,320,101,320,76,320C50.5,320,25,320,13,320L0,320Z"></path>;
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,64L60,58.7C120,53,240,43,360,64C480,85,600,139,720,160C840,181,960,171,1080,149.3C1200,128,1320,96,1380,80L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>;
        // res = <path fill={linearGradient} fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>;
        // res =   <path fill={linearGradient} fill-opacity="1" d="M0,32L40,69.3C80,107,160,181,240,192C320,203,400,149,480,160C560,171,640,245,720,272C800,299,880,277,960,234.7C1040,192,1120,128,1200,106.7C1280,85,1360,107,1400,117.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
        res = <path fill={linearGradient} fill-opacity="1" d="M0,224L40,197.3C80,171,160,117,240,112C320,107,400,149,480,138.7C560,128,640,64,720,58.7C800,53,880,107,960,138.7C1040,171,1120,181,1200,165.3C1280,149,1360,107,1400,85.3L1440,64L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>

        // const result = `<svg class="header-wave-top" height="200" width="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">` + res + `</svg>`;


        var response = <svg class="header-wave-top" height={headerWaveHeight} width="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <defs>
                <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: 'var(--bs-color-wave-1)', stopOpacity: 1 }} />
                    <stop offset="100%" style={{ stopColor: 'var(--bs-color-wave-2)', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
            {res}   </svg>;

        // TODO 
        response = null;

        return response;

        // return null;

        // return <div class="header-bg"></div>;


    }

    function waveBottom() {
        var res;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,224L48,224C96,224,192,224,288,192C384,160,480,96,576,96C672,96,768,160,864,202.7C960,245,1056,267,1152,240C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,266.7C560,277,640,267,720,234.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,165.3C1280,192,1360,224,1400,240L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,96L12.6,101.3C25.3,107,51,117,76,138.7C101.1,160,126,192,152,197.3C176.8,203,202,181,227,149.3C252.6,117,278,75,303,58.7C328.4,43,354,53,379,90.7C404.2,128,429,192,455,218.7C480,245,505,235,531,224C555.8,213,581,203,606,192C631.6,181,657,171,682,170.7C707.4,171,733,181,758,197.3C783.2,213,808,235,834,234.7C858.9,235,884,213,909,208C934.7,203,960,213,985,202.7C1010.5,192,1036,160,1061,128C1086.3,96,1112,64,1137,74.7C1162.1,85,1187,139,1213,138.7C1237.9,139,1263,85,1288,90.7C1313.7,96,1339,160,1364,202.7C1389.5,245,1415,267,1427,277.3L1440,288L1440,320L1427.4,320C1414.7,320,1389,320,1364,320C1338.9,320,1314,320,1288,320C1263.2,320,1238,320,1213,320C1187.4,320,1162,320,1137,320C1111.6,320,1086,320,1061,320C1035.8,320,1011,320,985,320C960,320,935,320,909,320C884.2,320,859,320,834,320C808.4,320,783,320,758,320C732.6,320,707,320,682,320C656.8,320,632,320,606,320C581.1,320,556,320,531,320C505.3,320,480,320,455,320C429.5,320,404,320,379,320C353.7,320,328,320,303,320C277.9,320,253,320,227,320C202.1,320,177,320,152,320C126.3,320,101,320,76,320C50.5,320,25,320,13,320L0,320Z"></path>;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,64L60,58.7C120,53,240,43,360,64C480,85,600,139,720,160C840,181,960,171,1080,149.3C1200,128,1320,96,1380,80L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>;
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>;
        // res =   <path fill="#0099ff" fill-opacity="1" d="M0,32L40,69.3C80,107,160,181,240,192C320,203,400,149,480,160C560,171,640,245,720,272C800,299,880,277,960,234.7C1040,192,1120,128,1200,106.7C1280,85,1360,107,1400,117.3L1440,128L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
        // res = <path fill="#0099ff" fill-opacity="1" d="M0,224L40,197.3C80,171,160,117,240,112C320,107,400,149,480,138.7C560,128,640,64,720,58.7C800,53,880,107,960,138.7C1040,171,1120,181,1200,165.3C1280,149,1360,107,1400,85.3L1440,64L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
        // return <svg class="header-wave-top" height={headerWaveHeight} width="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">{res}   </svg>;

        return null;

        // return <div class="bg-white w-100 h-100"></div>;


    }

    function componentDidMount() {
        if (typeof window !== "undefined") {
            window.onscroll = () => {
                let currentScrollPos = window.pageYOffset;
                let maxScroll = document.body.scrollHeight - window.innerHeight;
                let headerHeight = window.innerHeight;
                console.log(headerHeight);
                // console.log(maxScroll)
                if (currentScrollPos > headerHeight && currentScrollPos < maxScroll) {
                    setArrowVisible(1);
                    setCartBoxVisible(1);
                } else {
                    setArrowVisible(0);
                    setCartBoxVisible(0);
                }
            }
        }


    }

    function isLoggedIn() {
        return localStorage.getItem('login') ? true : false;
    }

    function isLoggedInText() {
        return localStorage.getItem('login') ? 'logged in' : 'logged off';
    }

    function login() {
        setDisplayModal(true);

    }


    function logout() {
        if (localStorage.getItem('login')) {
            var username = localStorage.getItem('username');
            let msg = 'User ' + cartCtx.getLoginUsername() + ' is log out [username]';
            // localStorage.removeItem('login');
            // localStorage.removeItem('username_' + username);
            // localStorage.removeItem('username');
            // cartCtx.changeLoginParam({ login: false, username: 'none' });
            cartCtx.logout();
            toast(msg);
        }
    }

    useEffect(componentDidMount, []);

    return (
        <div class="header-top">
            <div id="header" class="header">
                <div class="header-image">
                </div>

                {GlobalVariables.IS_DEV ? <div class="position-fixed top-0 end-50 p-2 bg-white border zindex-10000"><span>DEV {cartCtx.isLoggedIn() == true ? 'logged in' : 'logged off'}</span>
                </div>
                    :
                    null
                }

                {cartCtx.isLoggedIn() == true ?
                    <div class="badge badge-variant-green badge-variant-orange position-absolute bottom-0 end-0 p-3 m-3 border zindex-10000"><span>Přihlášen jako  {cartCtx.getLoginUsername()}</span></div>
                    : null
                }


                <a href="#"><h1>{GlobalPropertiesHeader.HEADER_TITLE}</h1></a>
                <div class="header-info">
                    {GlobalPropertiesHeader.HEADER_TEXT}
                </div>
                <a href="#kosik">
                    <div className={"header-cart-info header-cart-info-absolute"}>
                        <div>{cartCtx.totalPrice} Kc</div>
                        <div>
                            <i class="fa-solid fa-basket-shopping"></i>
                            <span>{cartCtx.totalAmount}</span>
                        </div>
                    </div>
                    <div className={cartBoxVisible ? "header-cart-info header-animate-1" : "header-cart-info header-animate-0 opacity-0"}>
                        <div>{cartCtx.totalPrice} Kc</div>
                        <div>
                            <i class="fa-solid fa-basket-shopping"></i>
                            <span>{cartCtx.totalAmount}</span>
                        </div>
                    </div>
                </a>
                <a href="#header">
                    <div class={arrowVisible ? "header-arrow-navigate-top header-animate-1" : "header-arrow-navigate-top header-animate-0 opacity-0"}>
                        <i class="fa-solid fa-arrow-up"></i>
                    </div>
                </a>

                <nav class="navbar navbar-expand-lg navbar-light">
                    <div class="container justify-content-end">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="header-navbar">
                                <a href="#kombucha">
                                    <li>
                                        <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i>Bambucha</div>
                                    </li>
                                </a>

                                <a href="#tibetka">
                                    <li>
                                        <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i>Tibetka</div>
                                    </li>
                                </a>
                                {cartCtx.isLoggedIn() ?

                                    <a href="#listOrders">
                                        <li>
                                            <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i>Pořadník</div>
                                        </li>
                                    </a>
                                    : null}

                                <a href="#kosik">
                                    <li>
                                        <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i>Košík</div>
                                    </li>
                                </a>

                                <a href="#contact">
                                    <li>
                                        <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i>Kontakt</div>
                                    </li>
                                </a>
                                {cartCtx.isLoggedIn() == false ?
                                    <a onClick={() => login()}>
                                        <li>
                                            <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Přihlásit</div></div>
                                        </li>
                                    </a>
                                    :
                                    <a onClick={() => logout()}>
                                        <li>
                                            <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Odhlášení</div></div>
                                        </li>
                                    </a>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>

                {false ?
                    <ul>
                        <a href="#kombucha">
                            <li>
                                <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Kombucha</div></div>
                            </li>
                        </a>
                        <a href="#tibetka">
                            <li>
                                <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Tibetka</div></div>
                            </li>
                        </a>
                        {cartCtx.isLoggedIn() ?
                            <a href="#listOrders">
                                <li>
                                    <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Poradnik</div></div>
                                </li>
                            </a>
                            : null}
                        <a href="#kosik">
                            <li>
                                <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Kosik</div></div>
                            </li>
                        </a>
                        <a href="#contact">
                            <li>
                                <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Kontakt</div></div>
                            </li>
                        </a>
                        {!isLoggedIn() ?
                            <div onClick={() => login()}>
                                <li>
                                    <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Login</div></div>
                                </li>
                            </div>
                            :
                            <div onClick={() => logout()}>
                                <li>
                                    <div class="d-flex align-items-center gap-3"><i class="fa-solid fa-arrow-down"></i><div>Logout</div></div>
                                </li>
                            </div>
                        }
                    </ul>
                    : null
                }

                <svg class="header-wave" height={headerWaveBottomHeight} width="100%" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">

                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,224L48,224C96,224,192,224,288,192C384,160,480,96,576,96C672,96,768,160,864,202.7C960,245,1056,267,1152,240C1248,213,1344,139,1392,101.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path> */}
                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,266.7C560,277,640,267,720,234.7C800,203,880,149,960,133.3C1040,117,1120,139,1200,165.3C1280,192,1360,224,1400,240L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path> */}
                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,96L12.6,101.3C25.3,107,51,117,76,138.7C101.1,160,126,192,152,197.3C176.8,203,202,181,227,149.3C252.6,117,278,75,303,58.7C328.4,43,354,53,379,90.7C404.2,128,429,192,455,218.7C480,245,505,235,531,224C555.8,213,581,203,606,192C631.6,181,657,171,682,170.7C707.4,171,733,181,758,197.3C783.2,213,808,235,834,234.7C858.9,235,884,213,909,208C934.7,203,960,213,985,202.7C1010.5,192,1036,160,1061,128C1086.3,96,1112,64,1137,74.7C1162.1,85,1187,139,1213,138.7C1237.9,139,1263,85,1288,90.7C1313.7,96,1339,160,1364,202.7C1389.5,245,1415,267,1427,277.3L1440,288L1440,320L1427.4,320C1414.7,320,1389,320,1364,320C1338.9,320,1314,320,1288,320C1263.2,320,1238,320,1213,320C1187.4,320,1162,320,1137,320C1111.6,320,1086,320,1061,320C1035.8,320,1011,320,985,320C960,320,935,320,909,320C884.2,320,859,320,834,320C808.4,320,783,320,758,320C732.6,320,707,320,682,320C656.8,320,632,320,606,320C581.1,320,556,320,531,320C505.3,320,480,320,455,320C429.5,320,404,320,379,320C353.7,320,328,320,303,320C277.9,320,253,320,227,320C202.1,320,177,320,152,320C126.3,320,101,320,76,320C50.5,320,25,320,13,320L0,320Z"></path> */}
                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,64L60,58.7C120,53,240,43,360,64C480,85,600,139,720,160C840,181,960,171,1080,149.3C1200,128,1320,96,1380,80L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path> */}
                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path> */}
                    {/* <path fill="#0099ff" fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,69.3C320,64,400,96,480,122.7C560,149,640,171,720,192C800,213,880,235,960,208C1040,181,1120,107,1200,74.7C1280,43,1360,53,1400,58.7L1440,64L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path> */}
                    {waveBottom()}



                </svg>

            </div >

            {cartCtx.checkSpinnerVisibility ?
                <div class="mask">
                    <div class="spinner-border text-primary position-fixed top-50 start-50" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                : null
            }
            {/* <div class="position-fixed top-0 start-0 border bg-white">
                Spinners: {cartCtx.checkSpinnerVisibility == true ? 'true' : 'false'}
            </div> */}

            <ModalLogin display={displayModal} handleCloseModal={handleCloseModal} />

        </div>
    );
}