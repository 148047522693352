import React, { useCallback, useContext, useState } from "react";
import styles from "../../css/box-layout.module.css";


import HeadingPrimary from "../Util/Headings";

import ImageGallery from "react-image-gallery";
import CartContext from "../../store/cart-context";
import GlobalVariables, { GlobalPropertiesContact, GlobalPropertiesFormControls, GlobalPropertiesImageGalleryKombucha, GlobalPropertiesKombucha, GlobalPropertiesTable, TOAST_MESSAGE_ALREADY_ADDED, debugMessage, isNull, populatePathVariables } from "../Util/GlobalVariables";

import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';


var imagesTodo = [
    {
        original: 'https://media.istockphoto.com/id/1243048868/cs/fotografie/kombucha-zdrav%C3%A9-p%C5%99%C3%ADrodn%C3%AD-probiotikum-ve-sklen%C4%9Bn%C3%A9-n%C3%A1dob%C4%9B.jpg?s=612x612&w=0&k=20&c=zEEVpRbH_0gYoV-OHOxDcJ-1iy-Q9g-4PW8Iv8UIgYc=',
        thumbnail: 'https://media.istockphoto.com/id/1243048868/cs/fotografie/kombucha-zdrav%C3%A9-p%C5%99%C3%ADrodn%C3%AD-probiotikum-ve-sklen%C4%9Bn%C3%A9-n%C3%A1dob%C4%9B.jpg?s=612x612&w=0&k=20&c=zEEVpRbH_0gYoV-OHOxDcJ-1iy-Q9g-4PW8Iv8UIgYc=',
    },
    {
        original: 'https://media.istockphoto.com/id/1189354901/cs/fotografie/selektivn%C3%AD-zam%C4%9B%C5%99en%C3%AD-sklenice-s-kombuchou-v-bl%C3%ADzkosti-l%C3%A1hve-na-%C5%A1edou-ubrousek.jpg?s=612x612&w=0&k=20&c=4fdpf76VL1JvSSMZbgC1A9D1OTmK-Sxxz-48s_iWusE=',
        thumbnail: 'https://media.istockphoto.com/id/1189354901/cs/fotografie/selektivn%C3%AD-zam%C4%9B%C5%99en%C3%AD-sklenice-s-kombuchou-v-bl%C3%ADzkosti-l%C3%A1hve-na-%C5%A1edou-ubrousek.jpg?s=612x612&w=0&k=20&c=4fdpf76VL1JvSSMZbgC1A9D1OTmK-Sxxz-48s_iWusE=',
    },
    {
        original: 'https://media.istockphoto.com/id/641308396/cs/fotografie/dom%C3%A1c%C3%AD-fermentovan%C3%BD-surov%C3%BD-kombucha-%C4%8Daj-s-r%C5%AFzn%C3%BDmi-p%C5%99%C3%ADchut%C4%9Bmi-zdrav%C3%BD-p%C5%99%C3%ADrodn%C3%AD-probiotick%C3%BD.jpg?s=612x612&w=0&k=20&c=EyUr06zdO8_OemQJO9B3_wK5AozLv14Am5GU8q5If3c=',
        thumbnail: 'https://media.istockphoto.com/id/641308396/cs/fotografie/dom%C3%A1c%C3%AD-fermentovan%C3%BD-surov%C3%BD-kombucha-%C4%8Daj-s-r%C5%AFzn%C3%BDmi-p%C5%99%C3%ADchut%C4%9Bmi-zdrav%C3%BD-p%C5%99%C3%ADrodn%C3%AD-probiotick%C3%BD.jpg?s=612x612&w=0&k=20&c=EyUr06zdO8_OemQJO9B3_wK5AozLv14Am5GU8q5If3c=',
    },
    {
        original: 'https://media.istockphoto.com/id/1204784436/cs/fotografie/zdrav%C3%BD-%C4%8Daj-kombucha-s-citronem-a-sko%C5%99ic%C3%AD.jpg?s=612x612&w=0&k=20&c=myg5Q8p4Cm3AabEI3laFdOTlF2n6WnNdLDCXBlhALuM=',
        thumbnail: 'https://media.istockphoto.com/id/1204784436/cs/fotografie/zdrav%C3%BD-%C4%8Daj-kombucha-s-citronem-a-sko%C5%99ic%C3%AD.jpg?s=612x612&w=0&k=20&c=myg5Q8p4Cm3AabEI3laFdOTlF2n6WnNdLDCXBlhALuM=',
    },
    {
        original: 'https://media.istockphoto.com/id/978771670/cs/fotografie/kombucha-druh%C3%BD-fermentovan%C3%BD-ovocn%C3%BD-%C4%8Daj-probiotick%C3%A9-j%C3%ADdlo.jpg?s=612x612&w=0&k=20&c=cPT6wEugIrWEIw2joAIk5JhVZ0w8sfbfr0Te-9v0MaA=',
        thumbnail: 'https://media.istockphoto.com/id/978771670/cs/fotografie/kombucha-druh%C3%BD-fermentovan%C3%BD-ovocn%C3%BD-%C4%8Daj-probiotick%C3%A9-j%C3%ADdlo.jpg?s=612x612&w=0&k=20&c=cPT6wEugIrWEIw2joAIk5JhVZ0w8sfbfr0Te-9v0MaA=',
    },
];


export default function FormKombucha({ handleOpenModal }) {

    const TYPE_FLAVOR_0 = 0;
    const TYPE_FLAVOR_1 = 1;
    const TYPE_FLAVOR_2 = 2;
    const TYPE_FLAVOR_3 = 3;

    const [choosedFlavor, setChoosedFlavor] = useState(TYPE_FLAVOR_0);
    const [amount, setAmount] = useState(1);
    const [userId, setUserId] = useState('');
    const [note, setNote] = useState('');


    const [isActiveFlavor, setIsActiveFlavor] = useState([true, false, false, false]);

    const [movies, setMovies] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    
    const cartCtx = useContext(CartContext);
    
    const [items, setItems] = useState([]);
    
    const [imageIndex, setImageIndex] = useState(0);


    const handleSubmitAddItem = event => {

        event.preventDefault();
        
        if (existsItemInCart(choosedFlavor)) {
            TOAST_MESSAGE_ALREADY_ADDED();
            return;
        }
        debugMessage('amount: ' + amount);
        debugMessage('choosedFlavor: ' + choosedFlavor);

        cartCtx.addItem({ choosedFlavor: choosedFlavor, amount: amount });
    }


    function existsItemInCart(choosedFlavor4Compare) {
        // const existsItem = items.find(item => item.choosedFlavor === choosedFlavor);
        console.log('green: ', items);
        const exists = cartCtx.items.some(item => item.choosedFlavor === choosedFlavor4Compare);
        console.log('exists: ', exists)
        return exists;
    }

    function findItem(id) {
        const existsItem = items.find(item => item.id === id);
        return existsItem;
    }

    function addToCartHandler() {
        console.log('addcart');

        // cartCtx.addItem({ choosedFlavor: choosedFlavor, amount: amount });
        // cartCtx.hiItem();
        // cartCtx.addItemToList({ flavor: 'bez', amount: 2 });
    }


    const CONST_PRICE_KOMBUCHA = 40;

    function removeFromCartHandler(id) {
        // console.log('removecart ', id);
        // cartCtx.removeItem(id);
    };


    const addIntoCart = (itemChoosedFlavor, itemAmount) => {
        console.log('addcart');
        let newId = 1 + getMaxId();

        const item = { flavor: itemChoosedFlavor, amount: itemAmount };

        // dispatchCartAction({type: 'ADD', item: item});


        // const newItem = { id: newId, name: itemChoosedFlavor, amount: itemAmount };
        // setCartItems(current => [...current, newItem]);

    }

    function setAmount4CartItem(id, amount) {

        console.log(amount);
        const item = findItem(id);
        item.amount = amount;
        const itemsTmp = [...items.filter(item => item.id !== id), item];
        itemsTmp.sort((a, b) => a.id > b.id ? 1 : -1);
        setItems(itemsTmp);
    }

    const getTotalPrice = useCallback(() => {
        const res = items.reduce((total, currVal) => total = total + currVal.amount * CONST_PRICE_KOMBUCHA, 0);
        return res;
        // setTotalPrice(res);
    });

    function getMaxId() {
        // const arr = [...cartCtx.items];
        // return Math.max(...arr.map(o => o.id));
        // setTotalPrice(res);
    };

    // if we would be using some external state then is better to use useCallback instead of useEffect
    // but this is const object and hence we need to call it in proper order (function is visible anywhere but object must be first declared and called after its code)
    const fetchMoviesHandler = useCallback(() => {

        try {
            let url = 'https://cat-fact.herokuapp.com/facts/';

            fetch(url).then(response => {
                if (!response.ok) {
                    throw new Error('something went wrong here!');
                }

                return response.json();
            }).then(data => {

                setMovies(data);
            })
        } catch (error) {
            console.log(error.message);
        }
    }, []);//second dependency


    // useEffect(() => {
    //     fetchMoviesHandler();
    // }, [fetchMoviesHandler]);



    function handleSubmit(event) {
        event.preventDefault();
        // alert(event.type);

        // fetchMoviesHandler();

        if (true) {
            console.log('Form submitted');
            if (handleOpenModal === undefined) {
                alert('greeen un')
            } else {
                // createOrder(choosedFlavor, note, amount, userId);
                handleOpenModal();
                items.map(item => {
                    console.log('loop: ', item.userId);
                    createOrder(item.choosedFlavor, item.amount);
                });
            }
        } else {
            console.log('Form has errors');
            return;
        }
    }

    function handleChoosedFlavor(e, flavor) {

        if (isNull(flavor)) {
            flavor = e.target.value.trim();
            flavor = parseInt(flavor);
        }
        debugMessage(typeof flavor);

        setChoosedFlavor(flavor);

        if (flavor == TYPE_FLAVOR_0) {
            setIsActiveFlavor([true, false, false, false]);
            // setImageIndex(0);
        }
        if (flavor == TYPE_FLAVOR_1) {
            setIsActiveFlavor([false, true, false, false]);
            // setImageIndex(1);
        }
        if (flavor == TYPE_FLAVOR_2) {
            setIsActiveFlavor([false, false, true, false]);
            // setImageIndex(2);
        }
        if (flavor == TYPE_FLAVOR_3) {
            setIsActiveFlavor([false, false, false, true]);
            // setImageIndex(2);
        }

        // e.target.classList.add('flavor-active');

    }

    function handleNote(e) {
        setNote(e.target.value);
    }

    function createOrder(flavor, amount) {

        // alert(amount);
        const pathVariables = {
            note: note,
            amount: amount,
            flavor: flavor,
            user_id: userId,
        };

        const url = GlobalVariables.URL_REST_API_PRODUCTS + populatePathVariables(pathVariables);

        console.log(url);
        try {
            fetch(url, {
                method: 'POST',
                mode: 'cors', // no-cors, cors, *same-origin (cors is default)
            })
                .then(function (response) {
                    // manipulate response object
                    // check status @ response.status etc.
                    if (!response.ok) {
                        throw new Error('something went wrong!');
                    }
                    return response.json(); // parses json
                })
                .then(function (myJson) {
                    // use parseed result
                    console.log(myJson);
                });
        } catch (error) {
            console.log(error.message);
        }
    }

    function getOrders() {
        // POST request using fetch with async/await
        const requestOptions = {
            headers: { Accept: 'application/json' }
        };
        // const response = fetch('localhost/php-rest-api/products', requestOptions)
        //     .then(response => console.log(response.json()));

        const url = GlobalVariables.URL_REST_API_PRODUCTS;

        try {
            fetch(url)
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error('something went wrong!');
                    }
                    return response.json(); // parses json
                })
                .then(function (myJson) {
                    // use parseed result
                    console.log('GET');
                    console.log(myJson);
                });
        } catch (error) {
            console.log(error.message);
        }
    }


    // useEffect(() => {
    //     getOrders();
    // }, []);




    let content = <p>Found no movies</p>;
    if (movies.length > 0) {
        content = <p>Found some movies</p>;
    }

    return (
        <div>

            {/* <div class="badge"> */}
            {/* number of items: {numberOfCartItems} */}
            {/* total amount: {cartState.totalAmount} */}
            {/* total amount: {cartState.items} */}
            {/* </div> */}

            <form onSubmit={(e) => handleSubmitAddItem(e)}>
                <div className={styles.container}>

                    {/* <Headingsheading2></Headingsheading2> */}


                    {/* <FormCreateHeader list={[{ name: 'Domu', url: '/' }, { name: 'Mam zajem', url: '' }]} /> */}

                    <div id="kombucha" className={styles.heading}>
                        <HeadingPrimary text={GlobalPropertiesKombucha.CONTENT_BOX_HEADER}></HeadingPrimary>
                    </div>
                    <div style={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                        {/* <HeadingPrimary text='Vas Kosik'></HeadingPrimary> */}
                        <div className={styles.icon}> </div>
                    </div>

                    <div className={styles.body}>
                        <div class="container">
                            <div class="grid">
                                <div class="row title">
                                    <div class="col-8">{GlobalPropertiesKombucha.CONTENT_BOX_HEADING}
                                    </div>
                                    <div class="col-4 text-end">
                                        <i class="fa-solid fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="row">
                                    <div class="col-md-5 text-center px-1" >
                                        {/* <ModalImage className="modal-image"
                                            hideDownload={true}
                                            small={'https://cdn.rohlik.cz/cdn-cgi/image/f=auto,w=650/https://cdn.rohlik.cz/uploads/images/K%C3%A1ja/kombuch.jpeg'}
                                            large={'https://cdn.rohlik.cz/cdn-cgi/image/f=auto,w=650/https://cdn.rohlik.cz/uploads/images/K%C3%A1ja/kombuch.jpeg'}
                                            alt="Kombucha"
                                        /> */}
                                        <ImageGallery
                                            items={GlobalPropertiesImageGalleryKombucha}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            showThumbnails={false}
                                            showIndex={false}
                                            showNav={true}
                                            slideDuration={parseInt(450)}
                                            slideInterval={parseInt(2000)}
                                            additionalClass="custom-image-gallery"
                                            startIndex={imageIndex}
                                        />

                                        {/* <a href="#" class="img-thumb-article">
                                            <img class="rounded w-75 px-1" src='https://cdn.rohlik.cz/cdn-cgi/image/f=auto,w=650/https://cdn.rohlik.cz/uploads/images/K%C3%A1ja/kombuch.jpeg' />
                                        </a> */}
                                    </div>
                                    <div class="col-md-7 px-1">
                                        <p>
                                            {GlobalPropertiesKombucha.CONTENT_BOX_TEXT_1}

                                            <br />
                                            <div class="d-flex gap-2 justify-content-start m-2 p-2 w-100 flex-wrap">
                                                <span className={isActiveFlavor[0] ? 'flavor-active badge badge-variant-brown' : 'badge badge-variant-brown'} onClick={(e) => handleChoosedFlavor(e, TYPE_FLAVOR_0)}>
                                                    Meruňka <i class="fa-solid fa-arrow-right"></i>
                                                </span>
                                                <span className={isActiveFlavor[1] ? 'flavor-active badge badge-variant-brown' : 'badge badge-variant-brown'} onClick={(e) => handleChoosedFlavor(e, TYPE_FLAVOR_1)}>
                                                    Mandarinka <i class="fa-solid fa-arrow-right"></i>
                                                </span>
                                                <span className={isActiveFlavor[2] ? 'flavor-active badge badge-variant-brown' : 'badge badge-variant-brown'} onClick={(e) => handleChoosedFlavor(e, TYPE_FLAVOR_2)}>
                                                    Borůvka <i class="fa-solid fa-arrow-right"></i>
                                                </span>
                                                <span className={isActiveFlavor[3] ? 'flavor-active badge badge-variant-brown' : 'badge badge-variant-brown'} onClick={(e) => handleChoosedFlavor(e, TYPE_FLAVOR_3)}>
                                                    Natural <i class="fa-solid fa-arrow-right"></i>
                                                </span>

                                                {/* <span onClick={() => handleChoosedFlavor(2)} class="badge badge-variant-white">Bezovy kvet</span>
                                            <span onClick={() => handleChoosedFlavor(3)} class="badge badge-variant-violet">Boruvka</span> */}
                                            </div>
                                        </p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <p>
                                            {GlobalPropertiesKombucha.CONTENT_BOX_TEXT_2}
                                        </p>
                                        <div class="px-3">
                                            <button class="badge badge-variant-green rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseText" aria-expanded="false" aria-controls="collapseText">
                                                {GlobalPropertiesFormControls.BUTTON_COLLAPSE}
                                            </button>

                                            <div class="collapse multi-collapse" id="collapseText">
                                                <div class="">
                                                    <p>
                                                        {GlobalPropertiesKombucha.CONTENT_BOX_TEXT_3}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="position-relative table-data mt-5 pb-5">
                                    <span class="position-top-right translate-top-right badge badge-variant-green rounded-pill">
                                        Max {GlobalPropertiesKombucha.MAX_AMOUNT} kusy na osobu
                                    </span>

                                    <div class="row table-row-header">
                                        <div class="col table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_PLACE}
                                        </div>
                                        <div class="col table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_CHOICE}
                                        </div>
                                        <div class="col table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_FLAVOR}
                                        </div>
                                        <div class="col-2 table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_AMOUNT}<br />
                                            {/* <span class="rounded-pill badge bg-secondary">max 4ks na osobu</span> */}

                                        </div>

                                    </div>
                                    <div class="row table-row pt-5">
                                        <div class="col table-col-cell">
                                            {/* <img class="img-thumbnail" src="https://ahvxpbwmeq.cloudimg.io/v7/images/stories/virtuemart/product/172087.jpg?width=350&org_if_sml=1"></img> */}
                                            {/* <select disabled class="form-select" aria-label="Default select example">
                                                <option selected value="1">Havirov</option>
                                                <option value="2">Ostrava</option>
                                                <option value="2">Frydek mistek</option>

                                            </select> */}
                                            <label class="form-control bgcolor-disabled">{ GlobalPropertiesContact.ADDRESS }</label>
                                        </div>
                                        <div class="col table-col-cell">
                                            {/* <select disabled class="form-select" aria-label="Default select example">
                                                <option value="1">Nasada</option>
                                                <option selected value="2">Limonada</option>
                                            </select> */} 
                                            <label class="form-control bgcolor-disabled">{ GlobalPropertiesKombucha.ITEM_FOR_SELL_1 }</label>
                                        </div>
                                        <div class="col table-col-cell">
                                            <select required onChange={(e) => handleChoosedFlavor(e)} value={choosedFlavor} class="form-select" aria-label="Default select example">
                                                <option value="0">Meruňka</option>
                                                <option value="1">Mandarinka</option>
                                                <option value="2">Borůvka</option>
                                                <option value="3">Natural</option>
                                                {/* <option value="3">Natural</option> */}
                                            </select>
                                        </div>
                                        <div class="col-2 table-col-cell">
                                            <input required onChange={(e) => setAmount(e.target.valueAsNumber)} type="number" defaultValue={1} min={1} max={GlobalPropertiesKombucha.MAX_AMOUNT} class="form-control form-count w-100" id="exampleFormControlInput1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="d-flex justify-content-end mt-2">
                                <button id="a" type="button" onClick={() => addItem()} className="button button-size-small">Do kosiku <i class="fa-solid fa-cart-plus"></i></button>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.footer}>
                        {/* <button onClick={handleSubmit} className="button">Odeslat objednavku</button> */}
                        <div class="text-center mt-4 d-flex justify-content-center gap-3">
                            <button type="submit" className="button">{GlobalPropertiesFormControls.BUTTON_CART} <i class="fa-solid fa-cart-plus"></i></button>
                        </div>
                    </div>
                </div>
            </form >
        </div >
    );
}

export function DropdownBox({ list }) {
    const listTodo = list.map((item, index) =>
        <option value={item.id}>{item.name}</option>
    );
}
