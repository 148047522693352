import { toast } from 'react-toastify';


const URL_BASE_DEV = 'http://localhost/php-rest-api/';
const URL_BASE_PROD = 'https://lukihocesty.jednoduse.cz/php-rest-api/';
// const URL_BASE = URL_BASE_DEV;
const URL_BASE = URL_BASE_PROD;
const URL_REST_API_EMAIL = URL_BASE + 'api/rest/user-management/email-send';
const URL_REST_API_PRODUCTS = URL_BASE + 'products/';

const IS_DEV = false;
// const IS_DEV = false;

export const Profiles = {
    // IS_DEV: false
}

const GlobalVariables = {
    // URL_BASE: URL_BASE_DEV,
    // URL_REST_API_BASE: URL_BASE_DEV,
    URL_REST_API_BASE: URL_BASE,
    URL_REST_API_EMAIL: URL_REST_API_EMAIL,
    URL_REST_API_PRODUCTS: URL_REST_API_PRODUCTS,
    waveHeight: 200,
    footerHeight: 200,
    contactEmail: 'gw.odkyseleni@seznam.cz',
    // IS_DEV: true,
    IS_DEV: IS_DEV,
}


export const TOAST_MESSAGE_ALREADY_ADDED = () => toastMessage('Tuto položku již máte přidanou :)');
export const TOAST_MESSAGE_CART_ITEM_ADDED = () => toastMessage('Položka byla přidána do košíku :)');
    

export const GlobalPropertiesItemsToSell = [
    { type: 0, title: 'Meruňka' },
    { type: 1, title: 'Mandarinka' },
    { type: 2, title: 'Borůvka' },
    { type: 3, title: 'Natural' },
    { type: 10, title: 'Tibetské houbičky (1 dávka)' },
]


export const GlobalPropertiesHeader = {
    HEADER_TITLE: "Bambucha",
    HEADER_TEXT: "Kombucha je fermentovaný čaj původem z Asie, který se konzumuje již tisíce let. Nejen, že má stejné výhody jako čaj, ale je také bohatá na prospěšná probiotika. Kombucha také obsahuje antioxidanty a blahodárně působí na organismus.",
}

export const GlobalPropertiesKombucha = {
    MAX_AMOUNT: 2,
    CONTENT_BOX_HEADER: 'Bambucha',
    CONTENT_BOX_HEADING: 'Zázračný fermentovaný nápoj Kombuchy',
    CONTENT_BOX_TEXT_1: 'Prodáváme domácí limonádu fresh Kombuchy. \nAktuální příchutě na výběr:',
    CONTENT_BOX_TEXT_2: 'Bambucha limonáda je fermentovaný, osvěžující a lehce perlivý nápoj. Je to po domácku vyrobená Kombucha. Takže žádné konzervanty ani jiná chemie. Po přijetí doporučujeme ihned popíjet. Vydrží po otevření v lednici cca týden. Pak již pomalu ztrácí své léčebné vlastnosti.' +
        '\n\n Dodáváme po 0,5 litru ve skleněné či plastové láhvi (podle dostupnosti). ' +
        'Pouze osobní odběr (Havířov). Po objednání se Vám ozveme a dohodneme detaily odběru.' +
        '\n\n Důležité info: Kapacita je omezená a proto je nutné počkat až bude Bambucha hotová.' +
        "" +
        "",
    CONTENT_BOX_TEXT_3:
        "👍 Celkově harmonizuje a vylaďuje tělo i mysl. Funguje jako blahodárný života budič." +
        "\n👍 pomáhá při regeneraci a posilování fyzické kondice" +
        "\n👍 čistí tělo od toxických látek, těžkých kovů, nikotinu, aj." +
        "\n👍 reguluje trávení, střevní mikrobiom a pomáhá čistit střevo od parazitů, kvasinek a hnilobných bakterií" +
        "\n👍 podporuje a posiluje přirozenou imunitu" +
        "\n👍 pomáhá jako prevence rakoviny či při její léčbě (čistí tělo a regeneruje buněčné stěny)" +
        "\n👍 podporuje činnost žláz s vnitřní sekrecí" +
        "\n👍 uvádí do rovnováhy kyseliny i zásady v organismu (normalizuje pH)" +
        "\n👍 má antibiotické účinky" +
        "\n👍 obsahuje zdravé kyseliny (významná kyselina glukuronová aj.) a enzymy" +
        "\n👍 obsahuje vitamíny skupiny B" +
        "" +
        "" +
        "",
    PRICE: 50,
    ITEM_FOR_SELL_1: 'Bambucha Limonáda',
    ITEM_FOR_SELL_2: 'Kombucha násada',

}

export const GlobalPropertiesTibetka = {
    MAX_AMOUNT: 2,
    CONTENT_BOX_HEADER: 'Tibetka',
    CONTENT_BOX_HEADING: 'Zázračný fermentovaný nápoj Tibetka',
    CONTENT_BOX_TEXT_1: "Prodáváme Tibetskou houbu.\nNa výběr:",
    CONTENT_BOX_TEXT_2: "Tibetka je úžasný Dar Přírody, jenž v jednoduchosti označuje Tibetské houbičky, které můžete používat pro výrobu domácího kefíru. " +
        "\n\nTento elixír je nabitý vitálními látkami včetně živých probiotik, které prospívají našemu zdraví. " +
        "Podle výzkůmů vyplývá, že střevo je náš druhý mozek a od něj se odráží i naše imunita. Proto je pravidelné zařazování probiotických potravin do jídelníčku velmi prospěšné pro harmonii našeho těla. A Tibetka nám v tom může velice dobře posloužit." +
        "\n\nTibetské houbičky pro výrobu kefíru." +
        "\nDodáváme po 1 polévkové lžíci včetně návodu. " + 
        ' Pouze osobní odběr (Havířov). Po objednání se Vám ozveme a dohodneme detaily odběru.'
        ,
    CONTENT_BOX_TEXT_3:
        "👍 Celkově harmonizuje a vylaďuje tělo i mysl." +
        "\n👍 pomáhá léčit cukrovku, zažívání, slinivku, žlučník, žaludek, záněty střev, průjmy, vředy, " +
        "\n ledviny, plíce, záněty průdušek, migrénu, alergie, ekzémy, alergii na laktózu, metastázy atd." +

        "\n👍 vyrábí vlastní antibiotika, protirakovinné složky, prospěšné bakterie i vitamíny. " +
        "\n👍 reguluje trávení, střevní mikrobiom a pomáhá čistit střevo od parazitů, kvasinek a hnilobných bakterií" +

        "\n👍 Pozn: vhodné i pro osoby s laktózovou intolerancí - kefír se laktózou živí. ",
    PRICE: 50,
    ITEM_FOR_SELL_1: GlobalPropertiesItemsToSell[4].title,

}





export const GlobalPropertiesCart = {
    CONTENT_BOX_HEADER: 'Košík',
    CONTENT_BOX_HEADING_1: 'Seznam položek',
    CONTENT_BOX_HEADING_2: 'Zadejte prosím další údaje',
    PRICE_CURRENCY: 'Kč',
}

export const GlobalPropertiesContact = {
    CONTENT_BOX_HEADER: 'Kontakt',
    CONTENT_BOX_HEADING_1: 'Email a adresa',
    CONTENT_BOX_HEADING_2: 'Máte dotaz?',
    EMAIL: 'gw.odkyseleni@seznam.cz',
    ADDRESS: 'Havířov',
}

export const GlobalPropertiesFormControls = {
    LABEL_EMAIL: "Váš Email",
    LABEL_USERNAME: "Vaše Jméno",
    LABEL_PASSWORD: "Heslo",
    LABEL_NOTE: 'Krátká Poznámka',
    LABEL_QUESTION: 'Dotaz',
    BUTTON_ORDER: 'Objednat',
    BUTTON_CART: 'Do Košíku',
    BUTTON_QUESTION: 'Odeslat Zprávu',
    BUTTON_CLOSE: 'Zavřít',
    BUTTON_LOGIN: 'Přihlásit',
    BUTTON_COLLAPSE: 'Co všechno léčí? 🙋',


}

export const GlobalPropertiesModal = {
    MODAL_ORDER_HEADER: "Objednávka odeslána",
    MODAL_ORDER_TEXT_1: "Děkujeme za objednávku. Jste zapsáni do pořadníku :) \n\nOzveme se Vám na zadaný email a vy nám potvrdíte svůj zájem :)",
    MODAL_EMAIL_HEADER: "Email byl odeslán",
    MODAL_EMAIL_TEXT_1: "Děkujeme za Váš vzkaz\n\n Ozveme se Vám hned jak to půjde :)",

}

export const GlobalPropertiesTable = {
    CONTENT_TABLE_COLUMN_PLACE: 'Místo předání',
    CONTENT_TABLE_COLUMN_CHOICE: 'Co je na výběr',
    CONTENT_TABLE_COLUMN_AMOUNT: 'Množství',
    CONTENT_TABLE_COLUMN_FLAVOR: 'Příchuť',

}

export const GlobalPropertiesErrors = {
    ERROR_SERVER_FAILURE: 'Ups... Vyskytla sa chyba na serveru. Prosíme kontaktujte nás formou emailu. Děkujeme za pochopení.',

}

export const GlobalPropertiesImageGalleryTibetka = [
    {
        original: 'https://media.istockphoto.com/id/1222613435/cs/fotografie/kef%C3%ADrov%C3%A1-zrna-v-d%C5%99ev%C4%9Bn%C3%A9-l%C5%BE%C3%ADci-a-kef%C3%ADrov%C3%BD-n%C3%A1poj-ve-sklenici.jpg?s=612x612&w=0&k=20&c=n3y6oD-W4KWeDM0Fu3e-aGMs5G8P8DUeYxYQCEvWbdU=',
        thumbnail: 'https://media.istockphoto.com/id/1222613435/cs/fotografie/kef%C3%ADrov%C3%A1-zrna-v-d%C5%99ev%C4%9Bn%C3%A9-l%C5%BE%C3%ADci-a-kef%C3%ADrov%C3%BD-n%C3%A1poj-ve-sklenici.jpg?s=612x612&w=0&k=20&c=n3y6oD-W4KWeDM0Fu3e-aGMs5G8P8DUeYxYQCEvWbdU=',
    },
    // {
    //     original: 'https://media.istockphoto.com/id/856010030/cs/fotografie/kef%C3%ADr.jpg?s=612x612&w=0&k=20&c=NKezVqSxpRqjOshiVMXx11lrV8dxGD6HRmeLgSCYe1w=',
    //     thumbnail: 'https://media.istockphoto.com/id/856010030/cs/fotografie/kef%C3%ADr.jpg?s=612x612&w=0&k=20&c=NKezVqSxpRqjOshiVMXx11lrV8dxGD6HRmeLgSCYe1w=',
    // },
    // {
    //     original: 'https://media.istockphoto.com/id/1079649112/cs/fotografie/tibetsk%C3%A1-kef%C3%ADrov%C3%A1-ml%C3%A9%C4%8Dn%C3%A1-houba-na-b%C3%ADl%C3%A9m-pozad%C3%AD-pohled-shora-kop%C3%ADrovat-prostor.jpg?s=612x612&w=0&k=20&c=u1eID3x-Eskr82OAwlqMx_hf-urR-gheUsASw_8hoHU=',
    //     thumbnail: 'https://media.istockphoto.com/id/1079649112/cs/fotografie/tibetsk%C3%A1-kef%C3%ADrov%C3%A1-ml%C3%A9%C4%8Dn%C3%A1-houba-na-b%C3%ADl%C3%A9m-pozad%C3%AD-pohled-shora-kop%C3%ADrovat-prostor.jpg?s=612x612&w=0&k=20&c=u1eID3x-Eskr82OAwlqMx_hf-urR-gheUsASw_8hoHU=',
    // },

]

export const GlobalPropertiesImageGalleryKombucha = [
    // {
    //     original: 'C:\Users\lukas.sipinka\Downloads\kombucha.jpeg',
    //     thumbnail: 'C:\Users\lukas.sipinka\Downloads\kombucha.jpeg'
    // },
    // {
    //     original: 'C:\Users\lukas.sipinka\Downloads\kombucha.jpeg',
    //     thumbnail: 'C:\Users\lukas.sipinka\Downloads\kombucha.jpeg'
    // },
    {
        original: 'https://media.istockphoto.com/id/1243048868/cs/fotografie/kombucha-zdrav%C3%A9-p%C5%99%C3%ADrodn%C3%AD-probiotikum-ve-sklen%C4%9Bn%C3%A9-n%C3%A1dob%C4%9B.jpg?s=612x612&w=0&k=20&c=zEEVpRbH_0gYoV-OHOxDcJ-1iy-Q9g-4PW8Iv8UIgYc=',
        thumbnail: 'https://media.istockphoto.com/id/1243048868/cs/fotografie/kombucha-zdrav%C3%A9-p%C5%99%C3%ADrodn%C3%AD-probiotikum-ve-sklen%C4%9Bn%C3%A9-n%C3%A1dob%C4%9B.jpg?s=612x612&w=0&k=20&c=zEEVpRbH_0gYoV-OHOxDcJ-1iy-Q9g-4PW8Iv8UIgYc=',
    },
    {
        original: 'https://media.istockphoto.com/id/1189354901/cs/fotografie/selektivn%C3%AD-zam%C4%9B%C5%99en%C3%AD-sklenice-s-kombuchou-v-bl%C3%ADzkosti-l%C3%A1hve-na-%C5%A1edou-ubrousek.jpg?s=612x612&w=0&k=20&c=4fdpf76VL1JvSSMZbgC1A9D1OTmK-Sxxz-48s_iWusE=',
        thumbnail: 'https://media.istockphoto.com/id/1189354901/cs/fotografie/selektivn%C3%AD-zam%C4%9B%C5%99en%C3%AD-sklenice-s-kombuchou-v-bl%C3%ADzkosti-l%C3%A1hve-na-%C5%A1edou-ubrousek.jpg?s=612x612&w=0&k=20&c=4fdpf76VL1JvSSMZbgC1A9D1OTmK-Sxxz-48s_iWusE=',
    },
    {
        original: 'https://media.istockphoto.com/id/641308396/cs/fotografie/dom%C3%A1c%C3%AD-fermentovan%C3%BD-surov%C3%BD-kombucha-%C4%8Daj-s-r%C5%AFzn%C3%BDmi-p%C5%99%C3%ADchut%C4%9Bmi-zdrav%C3%BD-p%C5%99%C3%ADrodn%C3%AD-probiotick%C3%BD.jpg?s=612x612&w=0&k=20&c=EyUr06zdO8_OemQJO9B3_wK5AozLv14Am5GU8q5If3c=',
        thumbnail: 'https://media.istockphoto.com/id/641308396/cs/fotografie/dom%C3%A1c%C3%AD-fermentovan%C3%BD-surov%C3%BD-kombucha-%C4%8Daj-s-r%C5%AFzn%C3%BDmi-p%C5%99%C3%ADchut%C4%9Bmi-zdrav%C3%BD-p%C5%99%C3%ADrodn%C3%AD-probiotick%C3%BD.jpg?s=612x612&w=0&k=20&c=EyUr06zdO8_OemQJO9B3_wK5AozLv14Am5GU8q5If3c=',
    },
    // {
    //     original: 'https://media.istockphoto.com/id/1204784436/cs/fotografie/zdrav%C3%BD-%C4%8Daj-kombucha-s-citronem-a-sko%C5%99ic%C3%AD.jpg?s=612x612&w=0&k=20&c=myg5Q8p4Cm3AabEI3laFdOTlF2n6WnNdLDCXBlhALuM=',
    //     thumbnail: 'https://media.istockphoto.com/id/1204784436/cs/fotografie/zdrav%C3%BD-%C4%8Daj-kombucha-s-citronem-a-sko%C5%99ic%C3%AD.jpg?s=612x612&w=0&k=20&c=myg5Q8p4Cm3AabEI3laFdOTlF2n6WnNdLDCXBlhALuM=',
    // },
    // {
    //     original: 'https://media.istockphoto.com/id/978771670/cs/fotografie/kombucha-druh%C3%BD-fermentovan%C3%BD-ovocn%C3%BD-%C4%8Daj-probiotick%C3%A9-j%C3%ADdlo.jpg?s=612x612&w=0&k=20&c=cPT6wEugIrWEIw2joAIk5JhVZ0w8sfbfr0Te-9v0MaA=',
    //     thumbnail: 'https://media.istockphoto.com/id/978771670/cs/fotografie/kombucha-druh%C3%BD-fermentovan%C3%BD-ovocn%C3%BD-%C4%8Daj-probiotick%C3%A9-j%C3%ADdlo.jpg?s=612x612&w=0&k=20&c=cPT6wEugIrWEIw2joAIk5JhVZ0w8sfbfr0Te-9v0MaA=',
    // },
];


export function populatePathVariables(array) {
    var url_suffix = '?';
    for (var key in array) {
        if (array.hasOwnProperty(key)) {
            url_suffix += key + '=' + array[key] + '&';
        }
    }
    const res = url_suffix.slice(0, -1);
    console.log(res);
    return res;
}

export function populateBaseUrl() {
    return GlobalVariables.URL_REST_API_BASE;
}

export function alertNoConnection() {
    if (GlobalVariables.IS_DEV) {
        alert('no connection to php localhost');
    }
}

export function checkConnection(connected) {
    if (connected) {
        return <span>db: connected</span>
    } else {
        return <span>db: not connected</span>
    }
}

export function debugMessage(msg) {
    if (GlobalVariables.IS_DEV) {
        console.log('[green] ' + msg);
        alert('[green] ' + msg);
    }
}

export function isNull(arg) {
    return arg === null || arg === undefined;
}

export function toastMessage(message) {
    toast(message);
}



export default GlobalVariables;