import React, { useContext, useState } from "react";
import styles from "../../css/box-layout.module.css";


import HeadingPrimary from "../Util/Headings";
// import ReactImageGallery from "../ReactImageGallery/ReactImageGallery";
import ImageGallery from "react-image-gallery";
import CartContext from "../../store/cart-context";
import { GlobalPropertiesContact, GlobalPropertiesFormControls, GlobalPropertiesImageGalleryTibetka, GlobalPropertiesTable, GlobalPropertiesTibetka, TOAST_MESSAGE_ALREADY_ADDED } from "../Util/GlobalVariables";

var imagesTodo = [
    // {
    //     original: 'https://picsum.photos/id/1018/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1015/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1019/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
    // },
];

export default function FormTibetka({ handleOpenModal }) {

    const [choosedFlavor, setChoosedFlavor] = useState(10);
    const [amount, setAmount] = useState(1);
    const [userId, setUserId] = useState('');
    const [note, setNote] = useState('');

    const [isActiveFlavor, setIsActiveFlavor] = useState([false]);

    const [movies, setMovies] = useState([]);

    const cartCtx = useContext(CartContext);

    const handleSubmitAddItem = event => {

        event.preventDefault();
        console.log('handleSubmit')

        if (existsItemInCart(choosedFlavor)) {
            TOAST_MESSAGE_ALREADY_ADDED();
            return;
        }

        console.log(amount);
        console.log(choosedFlavor);
        cartCtx.addItem({ choosedFlavor: choosedFlavor, amount: amount });
    }


    function existsItemInCart(choosedFlavor4Compare) {
        // const existsItem = items.find(item => item.choosedFlavor === choosedFlavor);
        const exists = cartCtx.items.some(item => item.choosedFlavor === choosedFlavor4Compare);
        return exists;
    }



    function handleChoosedFlavor(e, flavor) {

        console.log('val' + flavor);
        if (flavor == null) {
            console.log('NULL');
            flavor = e.target.value;
        }

        setChoosedFlavor(flavor);

        if (flavor == 10) {
            setIsActiveFlavor([true]);
        } else {
            setIsActiveFlavor([false]);
        }
        // if (flavor == 11) {
        //     setIsActiveFlavor([false, true]);
        // }

        // e.target.classList.add('flavor-active');

    }


    let content = <p>Found no movies</p>;
    if (movies.length > 0) {
        content = <p>Found some movies</p>;
    }

    return (
        <div>

            <form onSubmit={handleSubmitAddItem}>
                <div className={styles.container}>
                    {/* <ImageGallery items={images} />; */}
                    {/* <Headingsheading2></Headingsheading2> */}


                    {/* <FormCreateHeader list={[{ name: 'Domu', url: '/' }, { name: 'Mam zajem', url: '' }]} /> */}

                    <div id="tibetka" className={styles.heading}>
                        <HeadingPrimary text={GlobalPropertiesTibetka.CONTENT_BOX_HEADER}></HeadingPrimary>
                    </div>
                    <div style={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                        {/* <HeadingPrimary text='Vas Kosik'></HeadingPrimary> */}
                        <div className={styles.icon}> </div>
                    </div>

                    <div className={styles.body}>
                        <div class="container">
                            <div class="grid">
                                <div class="row title">
                                    <div class="col-8">{GlobalPropertiesTibetka.CONTENT_BOX_HEADING}
                                    </div>
                                    <div class="col-4 text-end">
                                        <i class="fa-solid fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="row">
                                    <div class="col-md-5 text-center px-1" >
                                        {/* <ModalImage className="modal-image"
                                            hideDownload={true}
                                            small={'https://www.spektrumzdravi.cz/img/clanky/8b103ec236fe643689524b84f1c79fc2/probiotic-milk-kefir-grains.jpg'}
                                            large={'https://www.spektrumzdravi.cz/img/clanky/8b103ec236fe643689524b84f1c79fc2/probiotic-milk-kefir-grains.jpg'}
                                            alt="Tibetka" /> */}
                                        <ImageGallery
                                            items={GlobalPropertiesImageGalleryTibetka}
                                            showBullets={false}
                                            showFullscreenButton={true}
                                            showPlayButton={false}
                                            showThumbnails={false}
                                            showIndex={false}
                                            showNav={true}
                                            slideDuration={parseInt(450)}
                                            slideInterval={parseInt(2000)}
                                            additionalClass="custom-image-gallery"
                                        />
                                    </div>
                                    <div class="col-md-7 px-1">
                                        <p>
                                            {GlobalPropertiesTibetka.CONTENT_BOX_TEXT_1}

                                            <br />
                                            <div class="d-flex gap-2 justify-content-center m-2 p-2 w-100">
                                                {/* <span className={isActiveFlavor[0] ? 'flavor-active badge badge-variant-white' : 'badge badge-variant-white'} onClick={(e) => handleChoosedFlavor(e, 10)}>Raw cisty kefir (400 ml)</span> */}
                                                <span className={'flavor-active badge badge-variant-brown'} onClick={(e) => handleChoosedFlavor(e, 10)} >
                                                    Tibetské houbičky (1 dávka) <i class="fa-solid fa-arrow-right"></i>
                                                </span>
                                            </div>
                                        </p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <p>
                                            {GlobalPropertiesTibetka.CONTENT_BOX_TEXT_2}
                                        </p>
                                        <div class="px-3">
                                            <button class="badge badge-variant-green rounded-pill" type="button" data-bs-toggle="collapse" data-bs-target="#collapseText" aria-expanded="false" aria-controls="collapseText">
                                                {GlobalPropertiesFormControls.BUTTON_COLLAPSE}
                                            </button>

                                            <div class="collapse multi-collapse" id="collapseText">
                                                <div class="">
                                                    <p>
                                                        {GlobalPropertiesTibetka.CONTENT_BOX_TEXT_3}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="position-relative table-data mt-5 pb-5">
                                    <span class="position-top-right  translate-top-right badge badge-variant-green rounded-pill">
                                        Max {GlobalPropertiesTibetka.MAX_AMOUNT} kusy na osobu
                                    </span>

                                    <div class="row table-row-header">
                                        <div class="col table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_PLACE}
                                        </div>
                                        <div class="col table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_CHOICE}
                                        </div>
                                        <div class="col-3 table-col-cell">
                                            {GlobalPropertiesTable.CONTENT_TABLE_COLUMN_AMOUNT}<br />
                                            {/* <span class="rounded-pill badge bg-secondary">max 4ks na osobu</span> */}

                                        </div>

                                    </div>
                                    <div class="row table-row pt-5">
                                        <div class="col table-col-cell">
                                            {/* <img class="img-thumbnail" src="https://ahvxpbwmeq.cloudimg.io/v7/images/stories/virtuemart/product/172087.jpg?width=350&org_if_sml=1"></img> */}
                                            {/* <select disabled class="form-select" aria-label="Default select example">
                                                <option selected value="1">Havirov</option>
                                                <option value="2">Ostrava</option>
                                                <option value="2">Frydek mistek</option>
                                            </select> */}
                                            <label class="form-control bgcolor-disabled">{ GlobalPropertiesContact.ADDRESS }</label>
                                        </div>
                                        <div class="col table-col-cell">
                                            <select required onChange={(e) => handleChoosedFlavor(e)} value={choosedFlavor} class="form-select" aria-label="Default select example">
                                                <option selected value="10">{ GlobalPropertiesTibetka.ITEM_FOR_SELL_1 }</option>
                                                {/* <option value="11">Tibetsky kefir (400 ml)</option> */}
                                            </select>
                                        </div>
                                        <div class="col-3 table-col-cell">
                                            <input required value={amount} onChange={(e) => setAmount(e.target.valueAsNumber)} type="number" min={1} max={2} class="form-control form-count" id="exampleFormControlInput1" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={styles.footer}>
                        {/* <button onClick={handleSubmit} className="button">Odeslat objednavku</button> */}
                        <div class="text-center mt-4 d-flex justify-content-center gap-3">
                            <button type="submit" className="button">{GlobalPropertiesFormControls.BUTTON_CART} <i class="fa-solid fa-cart-plus"></i></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export function DropdownBox({ list }) {
    const listTodo = list.map((item, index) =>
        <option value={item.id}>{item.name}</option>
    );
}
