import React, { useContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import FormKombucha from '../FormKombucha/FormKombucha';

import '../../css/App.scss';

import { createBrowserRouter } from 'react-router-dom';
import CartContext from '../../store/cart-context';
import Footer from '../Footer/Footer';
import FormCart from '../FormCart/FormCart';
import FormContact from '../FormContact/FormContact';
import FormTibetka from '../FormTibetka/FormTibetka';
import Header from '../Header/Header';
import ModalInfo from '../Modal/ModalInfo';
import OrderList from '../OrderList/OrderList';
import UseEffectSample from '../OrderList/UseEffectSample';
import GlobalVariables from '../Util/GlobalVariables';


function MainComponent() {
    const [showModal, setShowModal] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const cartCtx = useContext(CartContext);

    function handleCloseModal() {
        setShowModal(false);
    }

    function handleOpenModal() {
        setShowModal(true);
    }

    //JSX code old approach (JSX, react router < 6.4)
    const router = createBrowserRouter([
        { path: '/', element: <FormKombucha handleOpenModal={handleOpenModal} /> },
        { path: '/contact', element: <FormContact handleOpenModal={handleOpenModal} /> },
    ]);

    //array code new approach (js objects in array, object based solution)
    // const routerDefinitions = createRoutesFromElements(
    //     <Route>
    //         <Route path='/' element={<FormKombucha handleOpenModal={handleOpenModal} />} />
    //         <Route path='/contact' element={<FormContact handleOpenModal={handleOpenModal} />} />
    //     </Route>
    // );
    // const routerOld = createBrowserRouter(routerDefinitions);


    return (
        <div class="position-relative">

            <Header />

            <FormKombucha handleOpenModal={handleOpenModal} />
            <div class="my-20"></div>
            <FormTibetka handleOpenModal={handleOpenModal} />
            <div class="my-20"></div>

            {cartCtx.isLoggedIn() ?
                <OrderList handleOpenModal={handleOpenModal} />
                : null}

            <div class="my-20"></div>
            <FormCart handleOpenModal={handleOpenModal} />

            <div class="my-20"></div>
            <FormContact handleOpenModal={handleOpenModal} />


            {GlobalVariables.IS_DEV ?
                <div class="bg-white">
                    <div class="ul-issue">
                        <ul>
                            <h3>Issues:</h3>
                            <li><a href="https://stackoverflow.com/questions/77117910/reactjs-why-onclick-is-going-to-trigger-two-methods-instead-of-one">https://stackoverflow.com/questions/77117910/reactjs-why-onclick-is-going-to-trigger-two-methods-instead-of-one</a></li>
                            <li><a href="https://stackoverflow.com/questions/77152140/when-call-fetch-on-php-controller-then-it-fails-on-json">https://stackoverflow.com/questions/77152140/when-call-fetch-on-php-controller-then-it-fails-on-json</a></li>
                            <li>prepsat Co? na konkretni jmeno</li>
                            <li>reset query search: false</li>
                            <li>pridat toggle hide pan, tzv "vysouvatko" pro filtr</li>
                            <li>pridat polozku modal</li>
                            <li>pridat auth udemy</li>
                            <li>Auth
                                <ul>
                                    <li>udemy tutorial</li>
                                    <li>php prihlaseni cors zkusit poresit nebo napsat dotaz stack over</li>
                                    <li>jine moznosti security</li>
                                    <li>nebo zatim bez security popr. jen simple pro poradnik ROLE_ADMIN</li>
                                    <li>login formular modal</li>
                                    <li>localStorage ulozit info o prihlasenem userovi</li>
                                    <li>header prihlasen jako admin</li>
                                    <li>zobrazit pouze pro admina urcite komponenty</li>
                                    <li>auto prihlaseni po navsteve page useEffect</li>
                                </ul>
                            </li>
                            <li>pridat image gallery - ruzne fotky kombuchy atd.</li>
                            <li>automatizace deploymentu - jenkins nebo filezilla</li>
                            <li>pripravit database script model</li>
                            <li>Pridat Captcha pro contact form popr. i pro tvorbu objednavky https://developers.google.com/recaptcha/intro</li>
                            <li>Pridat email poslani klientovi po objednavce</li>
                            <li>Pridat try catch pro index.php tak, aby se pri vzniku chyby zapsala hlaska do logu. Jinak to neni videt a je treba to debugovat + rucne zachytavat. Nevypise stacktrace automaticky jako v Jave.</li>
                            <li>Pridat funkce v php, #cleancode</li>
                        </ul>
                    </div>
                </div>
                : null}

            <UseEffectSample props={5000} />
            <ToastContainer />
            <Footer />
            <ModalInfo messages={['ahoj']} title="Objednavka odeslana" display={displayModal} handleCloseModal={handleCloseModal} />
            {/* <Spinner /> */}

        </div>
    );
}

export default MainComponent;