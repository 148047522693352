import React, { useContext, useEffect, useRef, useState } from 'react';
import FormKombucha from './custom-components/FormKombucha/FormKombucha';

// import './css/App.scss';

import { ToastContainer } from 'react-toastify';
import FormCart from './custom-components/FormCart/FormCart';
import FormContact from './custom-components/FormContact/FormContact';
import FormTibetka from './custom-components/FormTibetka/FormTibetka';
import Header from './custom-components/Header/Header';
import EmailContactForm from './custom-components/InnovationFlow/EmailJs';
import OrderList from './custom-components/OrderList/OrderList';
import UseEffectSample from './custom-components/OrderList/UseEffectSample';
import CartContextProvider from './store/CartContextProvider';
import GlobalVariables, { Profiles, debugMessage, isDEV } from './custom-components/Util/GlobalVariables';
import Footer from './custom-components/Footer/Footer';
import CartContext from './store/cart-context';
import Spinner from './custom-components/Spinner/Spinner';
import ModalInfo from './custom-components/Modal/ModalInfo';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import MainComponent from './custom-components/MainComponent/MainComponent';

function App() {

    const [showModal, setShowModal] = useState(false);
    const [displayModal, setDisplayModal] = useState(false);
    const cartCtx = useContext(CartContext);

    function handleCloseModal() {
        setShowModal(false);
    }

    function handleOpenModal() {
        setShowModal(true);
    }

    //JSX code old approach (JSX, react router < 6.4)
    const router = createBrowserRouter([
        { path: '/', element: <FormKombucha handleOpenModal={handleOpenModal} /> },
        { path: '/contact', element: <FormContact handleOpenModal={handleOpenModal} /> },
    ]);

    //array code new approach (js objects in array, object based solution)
    // const routerDefinitions = createRoutesFromElements(
    //     <Route>
    //         <Route path='/' element={<FormKombucha handleOpenModal={handleOpenModal} />} />
    //         <Route path='/contact' element={<FormContact handleOpenModal={handleOpenModal} />} />
    //     </Route>
    // );
    // const routerOld = createBrowserRouter(routerDefinitions);


    return (
        <CartContextProvider>
            <MainComponent />
        </CartContextProvider>
    );
}


export default App;
