import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from "../../css/box-layout.module.css";



import { PaginationControl } from 'react-bootstrap-pagination-control';
import ModalImage from "react-modal-image";
import CartContext from "../../store/cart-context";
import { authHeader } from "../FormCart/helpers/auth-header";
import ModalUpdateOrder from "../Modal/ModalUpdateOrder";
import GlobalVariables, { alertNoConnection, populateBaseUrl, populatePathVariables } from "../Util/GlobalVariables";
import HeadingPrimary from "../Util/Headings";

// const URL = 'https://lukihocesty.jednoduse.cz/php-rest-api/products/';
// const URL = 'http://localhost/php-rest-api/products/';

export default function OrderList({ handleOpenModal }) {

    const [choosedFlavor, setChoosedFlavor] = useState(-1);
    const [amount, setAmount] = useState('');
    const [userId, setUserId] = useState('');
    const [note, setNote] = useState('');
    const [orders, setOrders] = useState([]);
    const [ordersPaginated, setOrdersPaginated] = useState([]);

    const [isActiveFlavor, setIsActiveFlavor] = useState([false, false, false]);

    const [movies, setMovies] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);
    const [choosedOrder, setChoosedOrder] = useState({});

    const [spinnerVisibility, setSpinnerVisibility] = useState(true);

    const [sortProperty, setSortProperty] = useState('id');
    const [sortDirection, setSortDirection] = useState('asc');
    const [filterFlavorProperty, setFilterFlavorProperty] = useState(0);
    const [searchProperty, setSearchProperty] = useState('ALL');
    const [searchValue, setSearchValue] = useState('');
    const [searchValueField, setSearchValueField] = useState('');
    const [selectedSearchProperty, setSelectedSearchProperty] = useState([true, false, false, false, false]);

    const ref = useRef(null);

    const cartContext = useContext(CartContext);


    const [hook, setHook] = useState();



    const handleEdit = order => {
        console.log("handleEdit");

        fetchOrder(order.id).then(order => {
            setChoosedOrder(order);
            setDisplayModal(true);
        });
    }


    // if we would be using some external state then is better to use useCallback instead of useEffect
    // but this is const object and hence we need to call it in proper order (function is visible anywhere but object must be first declared and called after its code)
    const fetchMoviesHandler = useCallback(() => {

        try {
            let url = 'https://cat-fact.herokuapp.com/facts/';

            fetch(url).then(response => {
                if (!response.ok) {
                    throw new Error('something went wrong here!');
                }

                return response.json();
            }).then(data => {

                setMovies(data);
            })
        } catch (error) {
            console.log(error.message);
        }
    }, []);//second dependency


    // useEffect(() => {
    //     fetchMoviesHandler();
    // }, [fetchMoviesHandler]);



    function handleSubmit(event) {
        event.preventDefault();

        // createOrder(choosedFlavor, note, amount, userId);
        // handleOpenModal();
    }

    function handleChoosedFlavor(e, flavor) {

        console.log('val' + flavor);
        if (flavor == null) {
            flavor = e.target.value;
        }

        setChoosedFlavor(flavor);

        if (flavor == 0) {
            setIsActiveFlavor([true, false, false]);
        }
        if (flavor == 1) {
            setIsActiveFlavor([false, true, false]);
        }
        if (flavor == 2) {
            setIsActiveFlavor([false, false, true]);
        }

        // e.target.classList.add('flavor-active');

    }

    function handleNote(e) {
        setNote(e.target.value);
    }

    async function checkConnection() {
        // let url = `http://localhost/php-rest-api/`;
        const url = populateBaseUrl();

        try {
            const res = await fetch(url, authHeader('GET', cartContext.authData));
            return true
        } catch (err) {
            return false
        }

    }

    function createOrder(flavor, note, amount, user_id) {

        const pathVariables = {
            note: note,
            amount: amount,
            flavor: flavor,
            user_id: userId,
        };

        const url = GlobalVariables.URL_REST_API_PRODUCTS + populatePathVariables(pathVariables);

        console.log(url);
        try {
            fetch(url, authHeader('POST', cartContext.authData))
                .then(function (response) {

                    alert('sadasd')
                    // manipulate response object
                    // check status @ response.status etc.
                    return response.json(); // parses json
                })
                .then(function (myJson) {
                    // use parseed result
                    console.log(myJson);
                });
        } catch (error) {
            console.log(error.message);
        }
    }

    const renderItemName = (name) => {
        if (name == 0) {
            return 'Merunka';
        } else if (name == 1) {
            return 'Mandarinka';
        } else if (name == 2) {
            return 'Boruvka';
        } else if (name == 3) {
            return 'Natural';
        } else if (name == 10) {
            return 'Tibetka';
        } else {
            return 'unknown';
        }
    };

    const renderOrderState = (state) => {
        if (state == 0) {
            return <div><i class="fa-solid fa-cart-shopping"></i></div>;
        } else if (state == 1) {
            return <div><i class="fa-solid fa-thumbs-up"></i></div>
        } else if (state == 2) {
            return <div><i class="fa-solid fa-person-digging"></i></div>
        } else if (state == 3) {
            return <div><i class="fa-solid fa-truck-fast"></i></div>
        } else if (state == 4) {
            return <div><i class="fa-solid fa-handshake-angle"></i></div>
        } else if (state == 5) {
            return <div><i class="fa-solid fa-ban"></i></div>
        } else {
            return <div><i class="fa-solid fa-circle-question"></i></div>
        }
        console.log("renderItemName ", state)
    };


    const handleDelete = order => {
        console.log("handleEdit");

        deleteOrder(order.id).then(data => {
            console.log('res: ');
            console.log(data);
            setSpinnerVisibility(true);
            handleFetchOrders();
        });
    }

    function handleCloseModal() {
        setDisplayModal(false);

        setSpinnerVisibility(true);
        handleFetchOrders();
    }


    function handleSaveOrder(order) {
        saveOrderPromise(order).then((parsedJson) => {
            setDisplayModal(false);
            setSpinnerVisibility(true);
            handleFetchOrders();
        });
    }

    async function handleFetchOrders() {
        const res = await fetchOrders().then(transformedData => {
            console.log('CAPTAIN 06')
            setOrders(transformedData);
            paginate(transformedData, 10, page);
        }).catch(err => {
            console.log('flow errors...');
        });

        setSpinnerVisibility(false);
    }

    const handleOnClickSortByProperty = (e) => {
        console.log('e: ' + e.currentTarget.id);
        if (e.currentTarget.id) {
            setSortProperty(e.currentTarget.id);
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        }
        setSpinnerVisibility(true);
    }

    const handleOnClickSearchPropertyByAllAndFlavor = (e) => {

        setSpinnerVisibility(true);
        console.log('e: ' + e.currentTarget.id);
        if (e.currentTarget.id) {
            console.log('CAPTAIN GREEN 9');

            setSearchProperty('');
            setSearchValue('');
            setSearchValueField('');
            if (e.currentTarget.id == 'ALL') {
                setSearchProperty('ALL');
            } else {
                setSearchProperty('flavor');
                setSearchValue(e.currentTarget.id);
            }

            // alert(e.currentTarget.id);
            // alert(searchValue);

            console.log('GREEN');

            if (e.currentTarget.id == 'ALL') {
                setSelectedSearchProperty([true, false, false, false, false]);
            }
            if (e.currentTarget.id == '0') {
                setSelectedSearchProperty([false, true, false, false, false]);
            }
            if (e.currentTarget.id == '1') {
                setSelectedSearchProperty([false, false, true, false, false]);
            }
            if (e.currentTarget.id == '2') {
                setSelectedSearchProperty([false, false, false, true, false]);
            }
            if (e.currentTarget.id == '10') {
                setSelectedSearchProperty([false, false, false, false, true]);
            }
        }
    }


    const handleOnClickSearchProperty = (e) => {
        setSpinnerVisibility(true);

        const value = e.target.value;

        console.log('val: ' + value);
        console.log('CAPTAIN GREEN 10')
        setSearchProperty('ALL');
        setSearchValue(value);
        setSearchValueField(value);
        // if (e.target.classList.contains('button-active')) {
        //     e.target.classList.remove('button-active');
        // } else {
        //     e.target.classList.add('button-active');
        // }


    }

    async function fetchOrders() {

        const res = await checkConnection();

        if (res === false) {
            alertNoConnection();
            return;
        }

        // const response = fetch('localhost/php-rest-api/products', requestOptions)
        //     .then(response => console.log(response.json()));

        const pageable = {

            sort: "true",
            sortProperty: sortProperty,
            sortDirection: sortDirection,
            search: "true",
            searchProperty: searchProperty,
            searchValue: searchValue,
            numberOfElements: 1,
            size: 10,
            totalPages: 10,
            page: 1,
        };
        // var url_suffix = URL + '?';
        // for (var key in pageable) {
        //     if (pageable.hasOwnProperty(key)) {
        //         url_suffix += key + '=' + pageable[key] + '&';
        //     }
        // }
        // console.log(url_suffix.slice(0, -1));
        // const url_suffix = populatePathVariables(pageable);
        const url_suffix = GlobalVariables.URL_REST_API_PRODUCTS + populatePathVariables(pageable);

        // debugMessage(cartContext.authData.username + ', ' + cartContext.authData.password);

        try {
            const response = await fetch(url_suffix, authHeader('GET', cartContext.authData));
            if (!response.ok) {
                throw new Error('something went wrong!');
            }

            const data = await response.json();
            if (!data) {
                throw new Error('something went wrong!');
            }
            if (data.hasOwnProperty('errorCode')) {
                console.error(data);
                throw new Error('something went wrong!', data);
            }

            console.log(data);
            const transformedData = data.map(order => {
                return {
                    id: order.id,
                    userId: order.user_id,
                    amount: order.amount,
                    flavor: order.flavor,
                    note: order.note,
                    created: order.created,
                    state: order.state,
                };
            });
            return transformedData;

        } catch (error) {
            console.error('ERROR GREEN LOG: ' + error.message);
            throw Error(error);
        }
    }


    async function fetchOrder(id) {

        const res = await checkConnection();

        if (res === false) {
            alertNoConnection();
            return;
        }

        try {
            const response = await fetch(GlobalVariables.URL_REST_API_PRODUCTS + id, authHeader('GET', cartContext.authData));
            const data = await response.json();
            const transformedData = {
                id: data.id,
                userId: data.user_id,
                amount: data.amount,
                flavor: data.flavor,
                note: data.note,
                created: data.created,
                state: data.state,
            };
            return transformedData;
        } catch (error) {
            console.log(error.message);
        }
    }


    async function deleteOrder(id) {

        const res = await checkConnection();

        if (res === false) {
            alertNoConnection();
            return;
        }

        try {
            const response = await fetch(GlobalVariables.URL_REST_API_PRODUCTS + id, authHeader('DELETE', cartContext.authData));

            if (!response.ok) {
                throw new Error('something went wrong!');
            }
            const result = await response.json();
            return result;

        } catch (error) {
            console.log('exception: ' + error.message);
        }
    }



    async function saveOrderPromise(order) {

        const res = await checkConnection();

        if (res === false) {
            alertNoConnection();
            return;
        }

        const pathVariables = {
            mode: 'update',
            id: order.id,
            user_id: order.userId,
            flavor: order.flavor,
            amount: order.amount,
            note: order.note,
            state: order.state,
        };

        const url = GlobalVariables.URL_REST_API_PRODUCTS + populatePathVariables(pathVariables);
        // let url = `http://localhost/php-rest-api/products?mode=update&id=${order.id}&note=${order.note}&amount=${order.amount}&flavor=${order.flavor}&user_id=${order.userId}`;

        try {
            const response = await fetch(url, authHeader('POST', cartContext.authData));

            if (!response.ok) {
                throw new Error('something went wrong!');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.log(error.message);
        }
    }

    const [page, setPage] = useState(1);



    useEffect(() => {
        setTimeout(() => {
            console.log('CAPTAIN HOOK (handleFetch)');
            handleFetchOrders();
            // setSpinnerVisibility(false);
            return
        }, 300);
    }, [page, sortDirection, searchProperty, cartContext.changeLoginParam]);

    useEffect(() => {
        const timer = setTimeout(() => {
            console.log('CAPTAIN HOOK (handleFetch)');
            handleFetchOrders();
            // setSpinnerVisibility(false);
        }, 1000);
        return () => {
            console.log('CLEANUP');
            clearTimeout(timer);
        }
    }, [searchValue]);

    useEffect(() => {
        setTimeout(() => {
            console.log('CAPTAIN HOOK (handleSpinner)');
            setSpinnerVisibility(false);
        }, 4000);
    }, [spinnerVisibility]);

    useEffect(() => {

        setTimeout(() => {
            console.log('CAPTAIN HOOK ([])');
            setSpinnerVisibility(false);
        }, 300);
    }, []);

    // onQuery();
    // onQuery2();
    // onQuery();

    let content = <p>Found no movies</p>;
    if (movies.length > 0) {
        content = <p>Found some movies</p>;
    }



    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        const arr = array.slice((page_number - 1) * page_size, page_number * page_size);
        setOrdersPaginated(arr);
    }

    return (
        <div>

            <form onSubmit={handleSubmit}>
                <div className={styles.container}>
                    {/* <ImageGallery items={images} />; */}
                    {/* <Headingsheading2></Headingsheading2> */}


                    {/* <FormCreateHeader list={[{ name: 'Domu', url: '/' }, { name: 'Mam zajem', url: '' }]} /> */}

                    <div id="listOrders" className={styles.heading}>
                        <HeadingPrimary text='Seznam objednavek'></HeadingPrimary>
                    </div>
                    <div style={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                        {/* <HeadingPrimary text='Vas Kosik'></HeadingPrimary> */}
                        <div className={styles.icon}> </div>
                    </div>

                    <div className={styles.body}>
                        <div class="container mb-5">
                            <div class="grid">
                                <div class="row title">
                                    <div class="col-md-8">Poradnik zakazniku
                                    </div>
                                    <div class="col-md-4 text-end">
                                        <i class="fa-solid fa-arrow-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="grid">
                                <div class="row">
                                    <div class="col-md-5 text-center" >
                                        <ModalImage className="modal-image"
                                            hideDownload={true}
                                            small={'https://plus.unsplash.com/premium_photo-1683141096869-b21fb229dd02?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'}
                                            large={'https://plus.unsplash.com/premium_photo-1683141096869-b21fb229dd02?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80'}
                                        />

                                    </div>
                                    <div class="col-md-6 px-1">
                                        <p>
                                            Seznam objednavek v poradi.
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div class="row mt-3 w-100">
                                <div class="container">
                                    <br />
                                    <div class="row text-end text-success">
                                        <h4>Filtr</h4>

                                        <div class="flex-wrap d-flex flex-row gap-3 justify-content-end p-3 text-black border-radius border-top border-success">

                                            <button className={selectedSearchProperty[0] ? 'button button-hover button-active' : 'button button-hover'} type="button" id="ALL" onClick={(e) => handleOnClickSearchPropertyByAllAndFlavor(e)}>Vse</button>
                                            <button className={selectedSearchProperty[1] ? 'button button-hover button-active' : 'button button-hover'} type="button" id="0" onClick={(e) => handleOnClickSearchPropertyByAllAndFlavor(e)}>Merunka</button>
                                            <button className={selectedSearchProperty[2] ? 'button button-hover button-active' : 'button button-hover'} type="button" id="1" onClick={(e) => handleOnClickSearchPropertyByAllAndFlavor(e)}>Mandarinka</button>
                                            <button className={selectedSearchProperty[3] ? 'button button-hover button-active' : 'button button-hover'} type="button" id="2" onClick={(e) => handleOnClickSearchPropertyByAllAndFlavor(e)}>Boruvka</button>
                                            <button className={selectedSearchProperty[4] ? 'button button-hover button-active' : 'button button-hover'} type="button" id="10" onClick={(e) => handleOnClickSearchPropertyByAllAndFlavor(e)}>Tibetka</button>
                                        </div>
                                        <div class="d-flex justify-content-end w-100 px-3">
                                            <div class="w-40"><input value={searchValueField} onChange={(e) => handleOnClickSearchProperty(e)} type="text" placeholder="Co hledate?" class="form-control form-count" id="exampleFormControlInput1" /></div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="position-relative table-data mt-3">

                                    <div class="row table-row-header">
                                        <div id="id" onClick={(e) => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Id
                                        </div>
                                        <div id="user_id" onClick={e => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Email
                                        </div>
                                        <div id="amount" onClick={e => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Kolik toho?
                                        </div>
                                        <div id="flavor" onClick={e => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Co?
                                        </div>
                                        <div id="state" onClick={e => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Stav objednavky
                                        </div>
                                        <div id="date" onClick={e => handleOnClickSortByProperty(e)} class="col table-col-cell">
                                            Datum objednavky
                                        </div>
                                        <div class="col table-col-cell2">
                                            Akce
                                        </div>

                                    </div>

                                    {ordersPaginated.map((order, index) => {
                                        return (

                                            <div className={index % 2 == 0 ? 'row table-row py-2' : 'table-row-line row table-row py-2'}>
                                                <div class="col table-col-cell">
                                                    {order.id}
                                                </div>
                                                <div class="col table-col-cell">
                                                    {order.userId}
                                                </div>
                                                <div class="col table-col-cell">
                                                    {order.amount}
                                                </div>
                                                <div class="col table-col-cell">
                                                    {renderItemName(order.flavor)}
                                                </div>
                                                <div class="col table-col-cell">
                                                    {renderOrderState(order.state)}
                                                </div>
                                                <div class="col table-col-cell">
                                                    {new Date(order.created).toISOString().split('T')[0]}
                                                </div>
                                                <div class="col table-col-cell2">
                                                    <button onClick={() => handleEdit(order)} type="button" class="button text-center px-2 py-1 me-2 ">edit</button>
                                                    <button onClick={() => handleDelete(order)} type="button" class="button text-center px-1 py-1">x</button>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {spinnerVisibility ?
                                        <div class="mask">
                                            <div class="spinner-border text-primary position-absolute top-50 start-50 zindex-fixed" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                        : null
                                    }

                                </div>
                            </div>

                            <div class="row my-2">
                                <PaginationControl
                                    page={page}
                                    between={4}
                                    total={orders === null || orders === undefined ? 1 : orders.length}
                                    limit={10}
                                    changePage={(page) => {
                                        paginate(orders, 10, page);
                                        setSpinnerVisibility(true);
                                        setPage(page);
                                    }}
                                    ellipsis={1}
                                />
                            </div>

                            {/* <div class="d-flex justify-content-start"> */}
                            <div class="mt-3 container d-flex flex-column w-50 justify-content-center">
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-cart-shopping"></i>
                                    </div>
                                    <div>
                                        objednavka vytvorena
                                    </div>
                                </div>
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-thumbs-up"></i>
                                    </div>
                                    <div>
                                        cekani na potvrzeni zavazne
                                    </div>
                                </div>
                                {/* </div> */}
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-person-digging"></i>
                                    </div>
                                    <div>
                                        vyroba kombuchy
                                    </div>
                                </div>
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-truck-fast"></i>
                                    </div>
                                    <div>
                                        expedice
                                    </div>
                                </div>
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-handshake-angle"></i>
                                    </div>
                                    <div>
                                        objednavka prodana
                                    </div>
                                </div>
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-ban"></i>
                                    </div>
                                    <div>
                                        objednavka zrusena
                                    </div>
                                </div>
                                <div class="legends-d-flex-column">
                                    <div>
                                        <i class="fa-solid fa-circle-question"></i>
                                    </div>
                                    <div>
                                        neznamy stav
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className={styles.footer}>
                        {/* <button onClick={handleSubmit} className="button">Odeslat objednavku</button> */}
                        <div class="text-center mt-4 d-flex justify-content-center gap-3">
                            <button id="save-btn" type="submit" className="button">Pridat objednavku</button>
                        </div>
                    </div>
                </div>
            </form>
            {
                displayModal ?
                    <ModalUpdateOrder choosedOrder={choosedOrder} handleSaveModal={handleSaveOrder} handleCloseModal={handleCloseModal} />
                    :
                    null
            }

        </div>
    );
}
