import React from "react";
import GlobalVariables from "../Util/GlobalVariables";

export default function Footer() {

    function footer() {
        // var render = <div class="footer">
        //     <svg class="position-relative" height={GlobalVariables.footerHeight} viewBox="0 -20 700 110" width="100%" preserveAspectRatio="none">
        //         <path transform="translate(0, -10)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" />
        //         <defs>
        //             <linearGradient id="grad3" x1="0%" y1="0%" x2="100%" y2="0%">
        //                 <stop offset="0%" style={{ stopColor: 'var(--bs-color-wave-1)', stopOpacity: 1 }} />
        //                 <stop offset="100%" style={{ stopColor: 'var(--bs-color-wave-2)', stopOpacity: 1 }} />
        //             </linearGradient>
        //         </defs>
        //         <path fill="url(#grad3)" d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" />
        //     </svg>
        //     <p class="footer-text container">
        //         <div><i class="fa-solid fa-copyright"></i> 2023 Bambucha. Všechna práva vyhrazena</div>
        //         <div><i class="fa-solid fa-envelope"></i> gw.odkyseleni@seznam.cz</div>
        //         <div><i class="fa-solid fa-feather"></i> LukePeace</div>
        //     </p>
        // </div>;;

        var render =
            <div class="mask-wave">
                <div class="mask-overlay">
                </div>
                <div class="footer-text-heading"><i class="fa-solid fa-heart-circle-check"></i> Na zdravi! <i class="fa-regular fa-face-smile-beam"></i></div>
                <p class="footer-text container">
                    <a href={'mailto:' + GlobalVariables.contactEmail + '?subject=Zprava'}><div><i class="fa-solid fa-envelope"></i> {GlobalVariables.contactEmail}</div></a>
                    <div><i class="fa-solid fa-copyright"></i> 2023 Bambucha. Všechna práva vyhrazena</div>
                    <div><i class="fa-solid fa-feather"></i> LukePeace</div>
                </p>
            </div>;

        return render;
    }
    //     
    return footer();

}

