import React, { useEffect, useState } from "react";
import CardContainer from "../Cards/CardContainer";
// import classes from './ModalInfo.module.css';
import ReactModal from "react-modal";
import { toast } from "react-toastify";

ReactModal.setAppElement('#main');

const ModalUpdateOrder = (props) => {

    // const [item, setItem] = useState(props.choosedOrder);
    const [flavor, setFlavor] = useState(props.choosedOrder.flavor);
    const [amount, setAmount] = useState(props.choosedOrder.amount);
    const [userId, setUserId] = useState(props.choosedOrder.userId);
    const [note, setNote] = useState(props.choosedOrder.note);
    const [state, setState] = useState(props.choosedOrder.state);

    const [isOpen, setIsOpen] = useState();


    let subtitle;


    function handleOnClickCloseModal() {
        props.handleCloseModal();
    }

    function handleOnClickSaveItem() {
        const item = {
            id: props.choosedOrder.id,
            flavor: flavor,
            amount: amount,
            userId: userId,
            note: 'note',
            state: state,
        };
        props.handleSaveModal(item);
        toast('Item with id ' + item.id + ' saved');
        // props.handleCloseModal();

    }

    function handleAfterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function setValue(e) {
        console.log(e.target.value);
        console.log(e.target.id);
        let value = e.target.value;
        let id = e.target.id;


        if (id === "userId") {
            setUserId(value);
        }
        if (id === "amount") {
            setAmount(value);
        }
        if (id === "note") {
            setNote(value);
        }
        if (id === "flavor") {
            setFlavor(value);
        }
        if (id === "state") {
            setState(value);
        }
    }

    function handleKeyDown(event) {

        if (event.key === 'Enter') {
            // TODO validation
            handleOnClickSaveItem();
        }

    }

    return (
        <div>
            <ReactModal
                isOpen={true}
                onAfterOpen={handleAfterOpenModal}
                onRequestClose={handleOnClickCloseModal}
                // style={customStyles}
                className="modal-custom"
                overlayClassName="overlay-custom"
                contentLabel="Example Modal"
            >

                {/* <div className={classes.backdrop}> */}
                {/* <div className={classes.modal}> */}

                <div class="modal-header">

                    <div class="iconBird"><i class="fa-solid fa-dove"></i></div>
                    <div>
                        Editace
                    </div>
                    <div onClick={handleOnClickCloseModal} class="iconClose"><i class="fa-solid fa-xmark"></i></div>

                </div>

                <div class="modal-content">
                    <form>
                        <div class="d-flex flex-column gap-4 w-75 mx-auto">
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>Id</label>
                                </div>
                                <div class="col-7">
                                    <input onKeyDown={handleKeyDown} disabled value={props.choosedOrder.id} type="text" class="form-control rounded-left-none" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>user id</label>
                                </div>
                                <div class="col-7">
                                    <input onKeyDown={handleKeyDown} id="userId" type="text" onChange={(e) => setValue(e)} value={userId} class="form-control rounded-left-none" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>Polozka</label>
                                </div>
                                <div class="col-7">
                                    <input onKeyDown={handleKeyDown} id="flavor" type="text" onChange={(e) => setValue(e)} value={flavor} class="form-control rounded-left-none" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>Kolik toho?</label>
                                </div>
                                <div class="col-7">
                                    <input onKeyDown={handleKeyDown} id="amount" type="text" onChange={(e) => setValue(e)} value={amount} class="form-control rounded-left-none" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 label-text">
                                    <label>Stav objednavky</label>
                                </div>
                                <div class="col-7">
                                <input onKeyDown={handleKeyDown} id="state" type="text" onChange={(e) => setValue(e)} value={state} class="form-control rounded-left-none" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button className="button" onKeyDown={(e) => handleKeyDown(e)} onClick={handleOnClickSaveItem}>Ulozit</button>
                    <button className="button" onClick={handleOnClickCloseModal}>Zavrit</button>
                </div>
            </ReactModal >
        </div>
    );
}

export default ModalUpdateOrder;