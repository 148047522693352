import React, { useCallback, useContext, useEffect, useId, useReducer, useState } from "react";
import styles from "../../css/box-layout.module.css";

import HeadingPrimary from "../Util/Headings";
import ReactImageGallery from "../ReactImageGallery/ReactImageGallery";
import ModalImage from "react-modal-image";
import CartContext from "../../store/cart-context";
import { ToastContainer, toast } from 'react-toastify';
import ModalInfo from "../Modal/ModalInfo";
import { YOUR_SERVICE_ID, YOUR_PUBLIC_KEY, YOUR_TEMPLATE_ID, YOUR_TEMPLATE_ID_ORDER, YOUR_TEMPLATE_ID_QUESTION } from "../InnovationFlow/EmailJs";
import emailjs from '@emailjs/browser';
import GlobalVariables, { GlobalPropertiesContact, GlobalPropertiesFormControls, GlobalPropertiesModal, debugMessage } from "../Util/GlobalVariables";
import { Link } from "react-router-dom";



var imagesTodo = [
    // {
    //     original: 'https://picsum.photos/id/1018/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1015/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1019/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
    // },
];


export default function FormContact({ handleOpenModal }) {

    const [items, setItems] = useState([]);
    const [userId, setUserId] = useState();
    const [userName, setUsername] = useState();
    const [message, setMessage] = useState();
    const [displayModal, setDisplayModal] = useState(false);


    const cartCtx = useContext(CartContext);


    async function handleSubmit(event) {
        event.preventDefault();

        console.log("handleSubmit");


        cartCtx.setSpinnerVisibility(true);
        setTimeout(() => {
            
            var templateParams = {
                email_from: userId,
                userName: userName,
                message: message,
            };
            
            if (!GlobalVariables.IS_DEV) {
                emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID_QUESTION, templateParams, YOUR_PUBLIC_KEY)
                .then((result) => {
                    // show the user a success message
                    debugMessage('sent');
                    setDisplayModal(true);
                    cartCtx.setSpinnerVisibility(false);
                }, (error) => {
                    // show the user an error
                    debugMessage('error');
                    console.error(error);
                    cartCtx.setSpinnerVisibility(false);
                });
            } else {
                toast('sent isDEV');
                setDisplayModal(true);
            }
        }, 1000);

    }


    function handleCloseModal() {
        setDisplayModal(false);
    }

    return (
        <div>

            {/* <div class="badge"> */}
            {/* number of items: {numberOfCartItems} */}
            {/* total amount: {cartState.totalAmount} */}
            {/* total amount: {cartState.items} */}
            {/* </div> */}


            <ModalInfo messages={GlobalPropertiesModal.MODAL_EMAIL_TEXT_1} title={GlobalPropertiesModal.MODAL_EMAIL_HEADER} display={displayModal} handleCloseModal={handleCloseModal} />

            {/* <p> Go to <Link to="/"> another page </Link></p> */}

            <form onSubmit={handleSubmit}>

                <div className={styles.container}>
                    {/* <ImageGallery items={images} />; */}
                    {/* <Headingsheading2></Headingsheading2> */}


                    {/* <FormCreateHeader list={[{ name: 'Domu', url: '/' }, { name: 'Mam zajem', url: '' }]} /> */}

                    <div id="contact" className={styles.heading}>
                        <HeadingPrimary text={GlobalPropertiesContact.CONTENT_BOX_HEADER}></HeadingPrimary>
                    </div>
                    <div style={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                        {/* <HeadingPrimary text='Vas Kosik'></HeadingPrimary> */}
                        <div className={styles.icon}> </div>
                    </div>

                    <div className={styles.body}>
                        <div class="container">

                            <div class="row title">
                                <div class="col-8">{GlobalPropertiesContact.CONTENT_BOX_HEADING_1}
                                </div>
                                <div class="col-4 text-end">
                                    <i class="fa-solid fa-map"></i>
                                </div>
                            </div>

                            <div class="grid">
                                <div class="row">
                                    <div class="col-md-5 text-center" >
                                        <ModalImage className="modal-image"
                                            hideDownload={true}
                                            small={'https://images.unsplash.com/photo-1596524430615-b46475ddff6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29udGFjdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60'}
                                            large={'https://images.unsplash.com/photo-1596524430615-b46475ddff6e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8Y29udGFjdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60'}
                                            alt="Kombucha"
                                        />
                                        {/* <a href="#" class="img-thumb-article">
                                            <img class="rounded w-75 px-1" src='https://cdn.rohlik.cz/cdn-cgi/image/f=auto,w=650/https://cdn.rohlik.cz/uploads/images/K%C3%A1ja/kombuch.jpeg' />
                                        </a> */}
                                    </div>
                                    <div class="col-md-6 px-1">
                                        <p>
                                            <div class="">
                                                <div class="container w-100 float-start">
                                                    <div class="row py-2"><i class="col-2 fa-solid fa-envelope"></i><div class="col">{GlobalPropertiesContact.EMAIL}</div></div>
                                                    {/* <div class="row py-2"><i class="col-2 fa-solid fa-phone"></i><div class="col">111 222 333</div></div> */}
                                                    <div class="row py-2"><i class="col-2 fa-solid fa-location-dot"></i><div class="col">{GlobalPropertiesContact.ADDRESS}</div></div>
                                                </div>
                                            </div>



                                        </p>
                                    </div>
                                </div>

                            </div>


                            <div class="row title">
                                <div class="col-8">{GlobalPropertiesContact.CONTENT_BOX_HEADING_2}
                                </div>
                                <div class="col-4 text-end">
                                    <i class="fa-regular fa-message"></i>
                                </div>
                            </div>

                            <div class="container p-2 m-4 w-75 mx-auto">
                                <div class="row mb-3">
                                    <div class="col-4 label-text">{GlobalPropertiesFormControls.LABEL_EMAIL}</div>
                                    <div class="col-8"><input minLength={5} required value={userId} onChange={(e) => setUserId(e.target.value)} type="email" placeholder="vas.email@domena.cz" class="rounded-left-none form-control form-count" id="exampleFormControlInput1" /></div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-4 label-text">{GlobalPropertiesFormControls.LABEL_USERNAME}</div>
                                    <div class="col-8"><input minLength={5} required value={userName} onChange={(e) => setUsername(e.target.value)} type="text" placeholder="Jméno" class="rounded-left-none form-control form-count" id="exampleFormControlInput1" /></div>
                                </div>
                                <div class="row">
                                    <div class="col-4 label-text">{GlobalPropertiesFormControls.LABEL_QUESTION}</div>
                                    <div class="col-8">
                                        <div class="form-group mx-auto">
                                            <textarea minLength={10} required value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Vzkaz (max 100 znaků)" maxLength={100} class="rounded-right-none form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                    <div className={styles.footer}>
                        {/* <button onClick={handleSubmit} className="button">Odeslat objednavku</button> */}
                        <div class="text-center mt-4 d-flex justify-content-center gap-3">
                            <button type="submit" className="button">{GlobalPropertiesFormControls.BUTTON_QUESTION}</button>
                        </div>
                    </div>
                </div>
            </form >
        </div >
    );
}
