import React from "react";
import styles4Headings from "../styles/headings.module.css";

export default function HeadingPrimary({text}) {
    return (
        <div>
            <div className={styles4Headings.headingPrimary}>
                {text}
            </div>
        </div>
    );
}

export function HeadingSecondary() {
    return (
        <div>
            <div className={styles4Headings.headingSecondary}>
                Secondary Heading
            </div>
        </div>
    );
}