import React, { useContext, useEffect, useState } from "react";
import CardContainer from "../Cards/CardContainer";
// import classes from './ModalInfo.module.css';
import ReactModal from "react-modal";
import CartContext from "../../store/cart-context";
import { GlobalPropertiesFormControls } from "../Util/GlobalVariables";

const ModalInfo = (props) => {


    let subtitle;

    const cartCtx = useContext(CartContext);


    function handleCloseModal() {
        props.handleCloseModal();
    }

    function handleAfterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    useEffect(() => {
        console.log('green2')
    }, [cartCtx.checkSpinnerVisibility]);

    return (
        <ReactModal
            isOpen={props.display}
            onAfterOpen={handleAfterOpenModal}
            onRequestClose={handleCloseModal}
            // style={customStyles}
            className="modal-custom"
            overlayClassName="overlay-custom"
            contentLabel="Example Modal"
        >

            {/* <div className={classes.backdrop}> */}
            {/* <div className={classes.modal}> */}

            <div class="modal-header">

                <div class="iconBird"><i class="fa-solid fa-dove"></i></div>
                <div>
                    {props.title ? props.title : null}
                </div>
                <div onClick={handleCloseModal} class="iconClose"><i class="fa-solid fa-xmark"></i></div>

            </div>

            <div class="modal-content">
                <div className="row">
                    <div class="col-md-6">
                        <div className="image-stage">
                            <img className="image-thumb" src="https://images.unsplash.com/photo-1611658871778-30c12b88bd25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80" />
                            <i class="image-icon fa-solid fa-dove"></i>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p>
                            {props.messages ? props.messages : null}
                            {/* {props.messages ? props.messages.map(m => {
                            return <p>
                                {m}
                            </p>
                        }) : null} */}
                        </p>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button className="button" onClick={handleCloseModal}>{GlobalPropertiesFormControls.BUTTON_CLOSE}</button>
            </div>
        </ReactModal>
    );
}

export default ModalInfo;