import { useEffect, useState } from "react";
import { GlobalPropertiesKombucha, GlobalPropertiesTibetka, TOAST_MESSAGE_CART_ITEM_ADDED } from "../custom-components/Util/GlobalVariables";
import CartContext from "./cart-context";

const defaultCartState = {
    items: [],
    totalAmount: 0
};

const cartReducer = (state, action) => {
    
    if (action.type === 'ADD') {
        let newId = 1;

        console.log('ADD');
        if (state.items.length > 0) {
            newId = newId + Math.max(...state.items.map(o => o.id));
            console.log(newId);
        }
        
        action.item.id = newId;
        const updatedItems = state.items.concat(action.item);
        
        // state.items = updatedItems;
        return {
            items: updatedItems,
            totalAmount: 0
        };
    }

    if (action.type === 'REMOVE') {        
        console.log('REMOVE');
        const arr = state.items.filter((item) => item.id !== action.id);
        return {
            items: arr,
            totalAmount: 0
        };
    }

    return defaultCartState;
};


const CartContextProvider = (props) => {

    // const [cartState, dispatchCartAction] = useReducer(cartReducer, defaultCartState);
    const [cartItems, setCartItems] = useState([]);
    const [loginParams, setLoginParams] = useState({ loggedIn: false, username: 'none' });
    const [cartTotalAmount, setCartTotalAmount] = useState(0);
    const [login, setLogin] = useState(false);
    const [authData, setAuthData] = useState({ username: 'user', password: 'user2023' });

    const CONST_PRICE_KOMBUCHA = GlobalPropertiesKombucha.PRICE;
    const CONST_PRICE_TIBETKA = GlobalPropertiesTibetka.PRICE;

    const [spinnerVisibility, setSpinnerVisibility] = useState(false);

    const numberOfCartItems = cartItems.reduce((curVal, item) => curVal = curVal + item.amount, 0);

    const totalPriceHandler = cartItems.reduce((total, currVal) => total = total + currVal.amount * (currVal.choosedFlavor !== 10 ? CONST_PRICE_KOMBUCHA : CONST_PRICE_TIBETKA), 0);

    const getMaxId = () => {
        const arr = [...cartItems];
        return Math.max(...arr.map(o => o.id));
        // setTotalPrice(res);
    };

    const addItemToCartHandler = (item) => { 
        console.log('addItemToCartHandler', cartItems.length);

        console.log(item);
        
        const itemNew = { id: (new Date).getTime(), choosedFlavor: item.choosedFlavor, amount: item.amount };
        setCartItems([...cartItems, itemNew]);
        console.log(cartItems);
        TOAST_MESSAGE_CART_ITEM_ADDED();
        
        // dispatchCartAction({type: 'ADD', item: item});
    };

    const removeItemFromCartHandler = (idSelected) => { 
        console.log('removeItemFromCartHandler');
        const itemsTmp = cartItems.filter((item => item.id !== idSelected));
        setCartItems(itemsTmp);
        // dispatchCartAction({type: 'REMOVE', id: id});
    };
    
    const changeLoginParamHandler = (loginParameters) => { 
        console.log('changeLoginParamHandler');
        setLoginParams({login: loginParameters.login, username: loginParameters.username});
    };

    const updateItemFromCartHandler = (item) => { 
        console.log('updateItemFromCartHandler');
        // const findItem = cartItems.find(i => i.id === item.id);
        const itemsTmp = [...cartItems.filter((obj => obj.id !== item.id)), item];
        itemsTmp.sort((a, b) => a.id > b.id ? 1 : -1);

        setCartItems(itemsTmp);
        // dispatchCartAction({type: 'REMOVE', id: id});
    };

    const hiItemHandler = () => { 
        console.log('hi item');
    };

    const addItemToCartHandler2 = (item) => { 
        console.log('hi handler2', item);
        // dispatchCartAction({type: 'ADD', item: item});
    };

    const removeAllHandler = _ => {
        setCartItems([]);
    }

    useEffect(() => {
        // setSpinnerVisibility(true);
        setTimeout(() => {
            console.log('CAPTAIN HOOK APP (handleSpinner)');
            setSpinnerVisibility(false);
        }, 4000);
    }, [spinnerVisibility]);

    useEffect(checkLoginHandler, []);

    const cartContext = {
        items: cartItems,
        totalAmount: numberOfCartItems,
        addItem: addItemToCartHandler,
        removeItem: removeItemFromCartHandler,
        updateItem: updateItemFromCartHandler,
        hiItem: hiItemHandler,
        addItemToList: addItemToCartHandler2,
        totalPrice: totalPriceHandler,
        removeAll: removeAllHandler,
        checkSpinnerVisibility: spinnerVisibility,
        setSpinnerVisibility: (value) => { setSpinnerVisibility(value); },
        loginParams: loginParams,
        changeLoginParam: changeLoginParamHandler,
        getLoginUsername: getLoginUsernameHandler,
        isLoggedIn: isLoggedInHandler,
        logout: logoutHandler,
        login: loginHandler,
        checkLogin: checkLoginHandler,
        authData: authData,
        setAuthData: (data) => setAuthData(data),
    };

    function getLoginUsernameHandler() {
        return localStorage.getItem('username');
    }

    function isLoggedInHandler() {
        return login;
    }

    function loginHandler(username, password) {
        
        // let authEncodedBase64 = window.btoa(username + ':' + password);
        // localStorage.setItem('username_' + username, authEncodedBase64);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        localStorage.setItem('login', true);
        setLogin(true);
    }

    function logoutHandler() {
        
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        localStorage.removeItem('login');
        // localStorage.removeItem('username_' + getLoginUsernameHandler());
        setLogin(false);
    }

    // Check if user is already logged in from cache [useEffect]
    function checkLoginHandler() {
        const res = localStorage.getItem('login') ? true : false;

        const login = {
            username: localStorage.getItem('username'),
            password: localStorage.getItem('password')
        };
        if (login.username && login.password && res) {
            setAuthData(login);
        }
        console.log(res)
        setLogin(res);
    }

    function authDataHandler() {

    }


    return <CartContext.Provider value={cartContext}>
        {props.children}
    </ CartContext.Provider>
};

export default CartContextProvider;