import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export const YOUR_SERVICE_ID = 'service_ot1mmtl';
export const YOUR_TEMPLATE_ID_ORDER = 'template_q7ccw49';
export const YOUR_TEMPLATE_ID_QUESTION = 'template_xyop8kd';
export const YOUR_PUBLIC_KEY = 'ywEKVtTWt_zdbrmIM';

const EmailContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        var templateParams = {
            id: 123,
            name: 'Mr Green',
            amount: '4',
            flavor: 'Tibetka',
            price: '160 Kc',
        };

        // emailjs.sendForm(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, form.current, YOUR_PUBLIC_KEY)
        emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID_ORDER, templateParams, YOUR_PUBLIC_KEY)
            .then((result) => {
                // show the user a success message
                toast('sent');
            }, (error) => {
                // show the user an error
                toast('error');
            });
    };

    return (
        <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <input type="text" name="user_name" />
            <label>Email</label>
            <input type="email" name="user_email" />
            <label>Message</label>
            <textarea name="message" />
            <input type="submit" value="Send" />
        </form>
    );
};

export default EmailContactForm;