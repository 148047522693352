import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "../../css/box-layout.module.css";

import { toast } from 'react-toastify';
import CartContext from "../../store/cart-context";
import ModalInfo from "../Modal/ModalInfo";
import GlobalVariables, { GlobalPropertiesCart, GlobalPropertiesErrors, GlobalPropertiesFormControls, GlobalPropertiesItemsToSell, GlobalPropertiesKombucha, GlobalPropertiesModal, GlobalPropertiesTibetka, populatePathVariables } from "../Util/GlobalVariables";
import HeadingPrimary from "../Util/Headings";
import { authHeader } from "./helpers/auth-header";
import { useId } from "react";


var imagesTodo = [
    // {
    //     original: 'https://picsum.photos/id/1018/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1018/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1015/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1015/250/150/',
    // },
    // {
    //     original: 'https://picsum.photos/id/1019/1000/600/',
    //     thumbnail: 'https://picsum.photos/id/1019/250/150/',
    // },
];


export default function FormCart({ handleOpenModal }) {

    const TYPE_FLAVOR_0 = "0";
    const TYPE_FLAVOR_1 = "1";
    const TYPE_FLAVOR_2 = "2";
    const MAX_AMOUNT = 2;

    const [choosedFlavor, setChoosedFlavor] = useState(TYPE_FLAVOR_0);
    const [amount, setAmount] = useState(1);
    const [userId, setUserId] = useState(GlobalVariables.IS_DEV ? 'lukassipinka@seznam.cz' : '');
    const [note, setNote] = useState(GlobalVariables.IS_DEV ? 'Go to the green' : '');
    const [displayModal, setDisplayModal] = useState(false);


    const [isActiveFlavor, setIsActiveFlavor] = useState([true, false, false]);

    const [movies, setMovies] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);

    // const cartCtx = useContext(CartContext);

    const [items, setItems] = useState([]);

    const cartCtx = useContext(CartContext);

    const [spinnerVisibility, setSpinnerVisibility] = useState(true);


    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log('CAPTAIN HOOK (handleSpinner)');
    //         setSpinnerVisibility(false);
    //     }, 2000);
    // }, [spinnerVisibility]);

    const renderItemName = (flavorType) => {

        var result = GlobalPropertiesItemsToSell.find(item => {
            console.log(item.type);
            return item.type === flavorType;
        });
        console.log("flavorType ", flavorType)
        console.log("title ", result !== undefined ? result.title : 'null')

        if (result !== undefined) {
            return result.title;
        } else {
            return 'unknown';
        }


        // if (flavorType == 0) {
        //     return 'Merunka';
        // } else if (flavorType == 1) {
        //     return 'Bezovka';
        // } else if (flavorType == 2) {
        //     return 'Boruvka';
        // } else if (flavorType == 10) {
        //     return 'Tibetska houba (1 davka)';
        // }
        // console.log("renderItemName ", flavorType)
    };

    const removeItem = idSelected => {

        cartCtx.removeItem(idSelected);
    }

    const updateItem = item => {

        cartCtx.updateItem(item);
    }

    function findItem(id) {
        const existsItem = cartCtx.items.find(item => item.id === id);
        return existsItem;
    }

    function addToCartHandler() {
        console.log('addcart');

        // cartCtx.addItem({ choosedFlavor: choosedFlavor, amount: amount });
        // cartCtx.hiItem();
        // cartCtx.addItemToList({ flavor: 'bez', amount: 2 });
    }


    const CONST_PRICE_KOMBUCHA = GlobalPropertiesKombucha.PRICE;
    const CONST_PRICE_TIBETKA = GlobalPropertiesTibetka.PRICE;

    function removeFromCartHandler(id) {
        // console.log('removecart ', id);
        // cartCtx.removeItem(id);
    };


    function setAmount4CartItem(id, amount) {

        if (isNaN(amount)) {
            amount = 1;
        }

        if (amount > MAX_AMOUNT) {
            amount = MAX_AMOUNT;
            toast('Dosaženo maximální množství');
        }

        console.log(amount);
        const item = findItem(id);
        console.log(amount);
        item.amount = amount;
        const itemsTmp = [...cartCtx.items.filter(item => item.id !== id), item];
        itemsTmp.sort((a, b) => a.id > b.id ? 1 : -1);

        cartCtx.updateItem(item);

        // setItems(itemsTmp);
        // itemsTmp.sort((a, b) => a.id > b.id ? 1 : -1);

    }


    function getMaxId() {
        // const arr = [...cartCtx.items];
        // return Math.max(...arr.map(o => o.id));
        // setTotalPrice(res);
    };

    // if we would be using some external state then is better to use useCallback instead of useEffect
    // but this is const object and hence we need to call it in proper order (function is visible anywhere but object must be first declared and called after its code)
    const fetchMoviesHandler = useCallback(() => {

        try {
            let url = 'https://cat-fact.herokuapp.com/facts/';

            fetch(url).then(response => {
                if (!response.ok) {
                    throw new Error('something went wrong here!');
                }

                return response.json();
            }).then(data => {

                setMovies(data);
            })
        } catch (error) {
            console.log(error.message);
        }
    }, []);//second dependency


    // useEffect(() => {
    //     fetchMoviesHandler();
    // }, [fetchMoviesHandler]);

    const [formIsValid, setFormIsValid] = useState();
    useEffect(() => {

        const timer = setTimeout(() => {
            console.log('FormIsValid');

            setFormIsValid(
                userId.includes('@')
            );
        }, 1000);

        return () => {
            console.log('CLEANUP');
            clearTimeout(timer);


        }
    }, [userId]);


    function handleSubmit(event) {
        event.preventDefault();

        cartCtx.setSpinnerVisibility(true);
        setTimeout(() => {


            console.log("handleSubmit");
            console.log(displayModal)
            console.log(cartCtx.items.length);

            if (cartCtx.items.length > 0) {

                console.log('waiting for response...');
                createOrders();

            } else {
                toast("Form has errors");
                console.log('Form has errors');
                return;
            }

        }, 1000);
    }

    // function populatePathVariables(array) {
    //     var url_suffix = GlobalVariables.URL_BASE + '?';
    //     for (var key in array) {
    //         if (array.hasOwnProperty(key)) {
    //             url_suffix += key + '=' + array[key] + '&';
    //         }
    //     }
    //     const res = url_suffix.slice(0, -1);
    //     console.log(res);
    //     return res;
    // }


    async function createOrders() {

        const res = await Promise.all(
            cartCtx.items.map(async (item) => {
                // console.log('loop: ', item.userId);
                const createRes = await createOrder(item.choosedFlavor, item.amount)
                    .then(result => {
                        console.log(result);
                        if (result.message === 'Product created') {
                            return true;
                        }
                        return false;
                        // cartCtx.removeAll();

                    }).catch(err => {
                        console.error(err);
                        return false;
                        // cartCtx.removeAll();
                    });
                return createRes;
            })
        );

        console.log(res);

        const checkSuccess4CreateOrder = arr => arr.every(i => i === true);
        if (checkSuccess4CreateOrder(res)) {
            //todo send email
            let msg = 'Zdravím, děkuji za objednávku. Vaše objednávka je aktuálně ve stavu: čekání na vyřízení \n### Toto je automaticky vygenerovaný email na základě Vaší objednávky.';
            // let msg = 'Green World :)';
            let subject = 'Bambucha - Nová objednávka';
            let email_to = userId;
            const emailRes = await sendEmail(email_to, subject, msg)
                .then(result => {
                    console.log(result);
                    if (result.message === 'Email sent') {
                        return true;
                    }
                    return false;

                }).catch(err => {
                    let msg = err;
                    console.log(msg);
                    return false;
                });

            if (emailRes === true) {
            }

            setDisplayModal(true);

            if (!GlobalVariables.IS_DEV) {
                cartCtx.removeAll();
            }
        } else {
            toast(GlobalPropertiesErrors.ERROR_SERVER_FAILURE);
        }
        cartCtx.setSpinnerVisibility(false);
    }

    async function createOrder(flavor, amount) {

        // TODO
        // return OrderService.createOrder(userId, note, flavor, amount);

        // alert(amount);
        // Example:
        // http://localhost/php-rest-api/products?note=Go%20to%20the%20green&amount=1&flavor=10&user_id=tim@green.cz

        const pathVariables = {
            note: note,
            amount: amount,
            flavor: flavor,
            user_id: userId,
        };

        const url = GlobalVariables.URL_REST_API_PRODUCTS + populatePathVariables(pathVariables);

        try {
            const response = await fetch(url, authHeader('POST', cartCtx.authData));

            if (!response.ok) {
                throw new Error('something went wrong!');
            }
            const result = await response.json();
            return result;

        } catch (error) {
            console.log(error.message);
            throw error;
        }

    }

    async function sendEmail(email, subject, message) {

        const pathVariables = {
            to: email,
            subject: subject,
            message: message,
        };

        const url = GlobalVariables.URL_REST_API_EMAIL + populatePathVariables(pathVariables);

        try {
            const response = await fetch(url, authHeader('POST', cartCtx.authData));

            if (!response.ok) {
                throw new Error('something went wrong!');
            }
            const result = await response.json();
            return result;

        } catch (error) {
            console.log(error.message);
            throw error;
        }

    }




    let content = <p>Found no movies</p>;
    if (movies.length > 0) {
        content = <p>Found some movies</p>;
    }

    function handleCloseModal() {
        setDisplayModal(false);
    }

    return (
        <div>

            {/* <div class="badge"> */}
            {/* number of items: {numberOfCartItems} */}
            {/* total amount: {cartState.totalAmount} */}
            {/* total amount: {cartState.items} */}
            {/* </div> */}

            <ModalInfo messages={GlobalPropertiesModal.MODAL_ORDER_TEXT_1} title={GlobalPropertiesModal.MODAL_ORDER_HEADER} display={displayModal} handleCloseModal={handleCloseModal} />


            <form onSubmit={handleSubmit}>

                <div className={styles.container}>
                    {/* <ImageGallery items={images} />; */}
                    {/* <Headingsheading2></Headingsheading2> */}


                    {/* <FormCreateHeader list={[{ name: 'Domu', url: '/' }, { name: 'Mam zajem', url: '' }]} /> */}

                    <div id="kosik" className={styles.heading}>
                        <HeadingPrimary text={GlobalPropertiesCart.CONTENT_BOX_HEADER}></HeadingPrimary>
                    </div>
                    <div style={{ textAlign: 'center', position: 'relative', width: '100%' }}>
                        {/* <HeadingPrimary text='Vas Kosik'></HeadingPrimary> */}
                        <div className={styles.icon}> </div>
                    </div>

                    <div className={styles.body}>
                        <div class="container">

                            <div class="row title">
                                <div class="col-8">{GlobalPropertiesCart.CONTENT_BOX_HEADING_1}
                                </div>
                                <div class="col-4 text-end">
                                    <i class="fa-solid fa-cart-plus"></i>
                                </div>
                            </div>


                            <div class="cart-table">
                                {cartCtx.items.map((item, index) => {
                                    return (
                                        <div class="cart-table-row">

                                            <div class="col-4">{renderItemName(item.choosedFlavor)}</div>
                                            <div class="col-4 fontsize-1">
                                                <input required value={item.amount} onChange={(e) => setAmount4CartItem(item.id, e.target.valueAsNumber)} type="number" default={1} min={1} max={MAX_AMOUNT} class="form-control form-count fsize-1" id="exampleFormControlInput1" />
                                            </div>
                                            <div class="col-2">{item.amount * (item.choosedFlavor !== 10 ? CONST_PRICE_KOMBUCHA : CONST_PRICE_TIBETKA)} {GlobalPropertiesCart.PRICE_CURRENCY}</div>
                                            <div class="col-2">
                                                <span onClick={() => removeItem(item.id)}>
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>
                                            </div>
                                            {/* {item.id} */}
                                        </div>
                                    );
                                })}


                                {/* <button type="button" onClick={((e) => removeFromCart(2))}>
                                    hi there
                                </button> */}

                                {cartCtx.items.length === 0 ?
                                    <div>
                                        Nemáte žádné přidané položky
                                    </div>
                                    :
                                    <div class="cart-table-row bg-green-light-small font-bold">
                                        <div class="col-6 text-start ps-4">Celkem:</div>
                                        <div class="col-6 text-end pe-4">{cartCtx.totalPrice} {GlobalPropertiesCart.PRICE_CURRENCY}</div>
                                    </div>
                                }
                            </div>


                            {/* <div class="row title">
                            <div class="col-md-8">Lecebna Kombucha
                            </div>
                            <div class="col-md-4 text-end">
                                <i class="fa-regular fa-message"></i>
                            </div>
                        </div>

                        <div class="row">
                            <p>
                                Kombucha (též kombuča) je lehce perlivý fermentovaný nápoj vzniklý z oslazeného černého nebo zeleného čaje, který je užíván jako funkční potravina. K fermentaci je užívána symbiotická kolonie bakterií a kvasinek, nazývaná též zkratkou SCOBY.
                            </p>
                        </div> */}
                            <div class="row title">
                                <div class="col-8">{GlobalPropertiesCart.CONTENT_BOX_HEADING_2}
                                </div>
                                <div class="col-4 text-end">
                                    <i class="fa-regular fa-message"></i>
                                </div>
                            </div>

                            <div class="container p-2 m-4 w-75 mx-auto">
                                <div class="row mb-3">
                                    <div class="col-4 label-text">{GlobalPropertiesFormControls.LABEL_EMAIL}</div>
                                    <div class="col-8"><input required value={userId} onChange={(e) => setUserId(e.target.value)} type="email" placeholder="vas.email@domena.cz" class="rounded-left-none form-control form-count" id="exampleFormControlInput1" /></div>
                                </div>
                                <div class="row">
                                    <div class="col-4 label-text">{GlobalPropertiesFormControls.LABEL_NOTE}</div>
                                    <div class="col-8">
                                        <div class="form-group mx-auto">
                                            <textarea value={note} onChange={(e) => setNote(e.target.value)} placeholder="Volitelné (max 100 znaků)" maxLength={100} class="rounded-right-none form-control" id="exampleFormControlTextarea1" rows="2"></textarea>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                    <div className={styles.footer}>
                        {/* <button onClick={handleSubmit} className="button">Odeslat objednavku</button> */}
                        <div class="text-center mt-4 d-flex justify-content-center gap-3">
                            <button type="submit" className="button"><i class="fa-regular fa-circle-check pe-2"></i>{GlobalPropertiesFormControls.BUTTON_ORDER}</button>
                        </div>
                    </div>
                </div>
            </form >



        </div >
    );
}

export function DropdownBox({ list }) {
    const listTodo = list.map((item, index) =>
        <option value={item.id}>{item.name}</option>
    );
}
